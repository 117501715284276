// This component contains the timer used in all games except for Phantom Lines and Finale.

import React, {useState, useEffect, useRef} from 'react';
import {Box, Typography} from '@mui/material';
import { useSocket } from '../contexts/SocketContext';

interface TimerProps {
  durationInSeconds: number;
  isLeader: boolean;
  teamName: string;
  startTimer: boolean;
  onTimeUpdate?: (remainingTime: number) => void;

  onTimeUp?: () => void;
  // counting prop

}

const Timer: React.FC<TimerProps> = ({ durationInSeconds, isLeader, teamName, onTimeUpdate, startTimer, onTimeUp }) => {
  const [timeLeft, setTimeLeft] = useState(durationInSeconds);
  const {socket} = useSocket();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setTimeLeft(durationInSeconds);
  }, [durationInSeconds]);

  // timer logic for non leaders
  useEffect(() => {
    if (!socket || isLeader) return;

    function handleBroadcastTimeChange(data: { time: number }) {
      setTimeLeft(data.time);

      if (onTimeUpdate) {
        onTimeUpdate(data.time);
      }

      if (data.time <= 0 && onTimeUp) {
        onTimeUp();
      }
    }

    socket.on('broadcastTimeChange', handleBroadcastTimeChange);

    return () => {
      socket.off('broadcastTimeChange', handleBroadcastTimeChange);
    };
  }, [socket, isLeader, onTimeUpdate, onTimeUp]);


  useEffect(() => {
    if (isLeader) {
      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (startTimer && durationInSeconds > 0) {
        startTimerFunction();
      }

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [ isLeader, startTimer]);

  function startTimerFunction() {

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        const newTimeLeft = prevTimeLeft - 1;
        if (onTimeUpdate) {
          onTimeUpdate(newTimeLeft);
        }

        // Only the leader broadcasts the remaining time to others
        if (isLeader && socket) {
          socket.emit('broadcastTimeChange', { teamName, time: newTimeLeft });
        }

        if (newTimeLeft <= 0) {
          if (intervalRef.current) clearInterval(intervalRef.current);
          if (onTimeUp) onTimeUp();
          return 0;
        }



        return newTimeLeft;
      });
    }, 1000);
  }


  function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  return (
      <Box
          sx={{
            backgroundColor: 'white',
            paddingX: '22px',
            paddingY: '11px',
            borderRadius: '14px',  // Adjust to make the corners more or less rounded
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',  // Optional: adds a subtle shadow for a floating effect
            display: 'inline-block',  // Keeps the box size compact to the text
            textAlign: 'center',  // Centers the text in the box
            ml: '20px'
          }}
      >
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
          {formatTime(timeLeft)}
        </Typography>
      </Box>
  );
};

export default Timer;