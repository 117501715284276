import React, {FC} from "react";
import TutorialRoom from "./TutorialRoom/TutorialRoom";
import PolicyGame from "./PuzzlingPolicies/PolicyGame";
import HomeHygiene from './HomeHygiene/HomeHygiene';
import PhantomLines from "./PhantomLines/PhantomLines";
import LockdownLegends from "./LockdownLegends/LockdownLegends";
import InsiderInsights from "./InsiderInsights/InsiderInsights";
import {GameProps} from "./types";
import {InstructionsType} from "../lobbies/types";

export const gameComponents: { [key: number]: FC<GameProps> } = {

    0: TutorialRoom,
    1: PolicyGame,
    2: HomeHygiene,
    3: PhantomLines,
    4: LockdownLegends,
    5: InsiderInsights

};


interface GameRendererProps {
    currGame: number;
    setInstructions: (instructions: InstructionsType) => void;
    setOnCompleteMessage: (message: string) => void;
    setTimeLimit: (time: number) => void;
    isLeader: boolean;
    isInstructionPopupOpen: boolean;
    leaderName: string;
    currQuestionIndex: number;
    gameScore: number;
    updateVoteMessage: (voteMessage: string) => void;
    handleSubmit: (isCorrect: boolean, scoreIncrement: number) => void;
    onLeaderAction: (leaderAction: string, data?: any) => void;
    handleQuestionIndexUpdate: (newQuestionIndex: number) => void;
    endGame: () => void;
}
const GameRenderer: FC<GameRendererProps> = ({ currGame, setInstructions, setOnCompleteMessage, isInstructionPopupOpen, setTimeLimit, isLeader, leaderName, currQuestionIndex, gameScore, updateVoteMessage, handleSubmit, onLeaderAction, handleQuestionIndexUpdate, endGame }) => {
    const GameComponent = gameComponents[currGame]; // Look up the game component
    return (
        <>
            <GameComponent
                setInstructions={setInstructions}
                isInstructionPopupOpen={isInstructionPopupOpen}
                setOnCompleteMessage={setOnCompleteMessage}
                setTimeLimit={setTimeLimit}
                isLeader={isLeader}
                leaderName={leaderName}
                currQuestionIndex={currQuestionIndex}
                gameScore={gameScore}
                updateVoteMessage={updateVoteMessage}
                onSubmit={handleSubmit}
                onLeaderAction={onLeaderAction}
                onQuestionIndexUpdate={handleQuestionIndexUpdate}
                onComplete={endGame}
            />
        </>
    );
};

export default GameRenderer;