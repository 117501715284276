import {Level} from "./types";


export const levels: Level[] = [
    {level: "Question 1", points: 1},
    {level: "Question 2", points: 2},
    {level: "Question 3", points: 3},
    {level: "Question 4", points: 4},
    {level: "Question 5", points: 5},
    {level: "Question 6", points: 6},
    {level: "Question 7", points: 7},
    {level: "Question 8", points: 8},
    {level: "Question 9", points: 9},
    {level: "Question 10", points: 10},
];

export const tickSound = require('./assets/clock-tick-single.mp3');
export const dingSound = require('./assets/correctding.mp3');
export const buzzerSound = require('./assets/wrongbuzzer.mp3');
