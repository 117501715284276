import React from 'react';
import { Box, Typography } from '@mui/material';
import cyberiumLogo from '../assets/CyberiumLogo.png'; // Ensure the path to the logo is correct

/* 
The Cyberium and Visier Logos that are at the bottom of pretty much every page in the escape room. WE made them constant throughout the page so even 
if you scroll you can see it.
*/

const Logos: React.FC = () => {
    return (
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', backgroundColor: '#f9f9f9' }}>
        <Box sx={{display: 'flex', alignItems: 'center', mr: '2%'}}>
          <Typography sx={{mr: '8px', fontSize: '1rem', color: '#000'}}>Powered by:</Typography>
          <Box
            component="img"
            src={cyberiumLogo}
            alt="Cyberium Logo"
            sx={{
              height: { xs: '5%', sm: '7.5%', md: '10%', lg: '12.5%' }, // Adjusted height percentages
              maxHeight: '45px', // Reduced maximum height
            }}
          />
        </Box>
      </Box>
    );
  };

export default Logos;
