import React, {useState, useEffect, ChangeEvent} from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Alert,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogContent,
    DialogActions
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {styled, ThemeProvider} from '@mui/material/styles';
import {theme} from '../../styling/palette';
import tutorialImage from './TutorialImage.png';

import {GameProps} from "../types";
import eventEmitter from "../../contexts/EventEmitter";
import {useSocket} from "../../contexts/SocketContext";
import {useTeam} from "../../contexts/TeamContext";
import MusicPlayer from "../../components/MusicPlayer";

const tutorialMusic = require('../../assets/Tutorial.mp3');

const musicSrcList: string[] =[tutorialMusic];

const InvisibleAnchor = styled('div')<{
    top: string;
    right: string
}>`
  position: absolute;

  width: 40px;
  height: 0;
  pointer-events: none;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
`;

const popups = [
    {
        message: "Press this button to see your full team list and the current team leader.",
        anchorPosition: {top: "10px", right: "55vw"},
    },
    {
        message: "This icon displays whether you are the leader or participant in the current game.",
        anchorPosition: {top: "10px", right: "220px"},
    },
    {
        message: "Press the help button to alert the facilitator if your team needs help. A facilitator will join your session shortly.",
        anchorPosition: {top: "10px", right: "160px"},
    },
    {
        message: "The game timer is displayed here to indicate the time remaining in the game. Once the time runs out, you will be progressed to the next game!",
        anchorPosition: {top: "10px", right: "60px"},
    }
];


const PopupBody = styled('div')`
  padding: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.6rem;
  border-radius: 20px;
`;

// Styled component for the Close Button inside Popup
const CloseButton = styled(Button)`
  margin-top: 12px;
  padding: 4px 12px;
  font-size: 0.75rem; /* Smaller font size */
  color: #404040; /* Light grey text color */
  background-color: #e0e0e0; /* Light grey background color */
  border-radius: 20px; /* More rounded corners */
  box-shadow: none;
  text-transform: none; /* Keep the text normal-case */

  &:hover {
    background-color: #b5b5b3;
  }
`;


const images = [tutorialImage];
const questions: string[] = ["What is 2 + 2 / 2?"];

const whys: string[] =
    ["This is where the correct or incorrect answer explanation will be."]

const options: string[][] = [
    ["A. 1", "B. 2", "C. 3", "D. 2010"]];

const answers: string[] = ["C"];

// component representing the Lockdown Legends game.

const TutorialRoom: React.FC<GameProps> = (
    {
        setInstructions,
        setTimeLimit,
        setOnCompleteMessage,
        leaderName,
        currQuestionIndex,
        isInstructionPopupOpen,
        gameScore,
        updateVoteMessage,
        onQuestionIndexUpdate,
        onComplete,
    }) => {
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [questionStatus, setQuestionStatus] = useState("neutral");
    const [explanationOpen, setExplanationOpen] = useState(false);
    const { setIsLeader} = useTeam();

    const {socket} = useSocket();

    // Tutorial Popups
    const [currPopupIndex, setCurrPopupIndex] = useState(-1);
    const [canShowPopup, setCanShowPopup] = useState(false);

    const isLeader = true;

    useEffect(() => {
        console.log("Setting isLeader to be true in tutorial room");
        setIsLeader(true);

        setInstructions({
            title: 'Tutorial Room Instructions',
            subtitle: 'This tutorial will prepare you to navigate the game and learn about the features you may need to use throughout the activity.',
            content: [
                "This is not a real room, and points will not be counted.",
                "",

                <span><b>1. Game Instructions</b></span>,
                "Instructions will be displayed each game to guide you through the gameplay.",
                "",

                <span><b>2. Team Roles</b></span>,
                "There are two roles within a team, team leaders and regular players.",
                "",
                "The team leader will be chosen at random at the start of each room and will have the ability to interact directly with the game.",
                "",
                "Other players will only be able to vote on the leader's answer selections.",
                "",

                <span><b>3. Collaboration</b></span>,
                "Work together as a team to discuss and decide on your answers.",
                "",
                "Press the Alert Facilitator icon in the top right if you are stuck or need help.",
                "",

                <span><b>Enjoy the Game!</b></span>,

            ]
        });


            setOnCompleteMessage('You completed the tutorial! Press here to proceed to the first game.');


        setTimeLimit(10 * 60);
    }, []);

    useEffect(() => {
        if (canShowPopup || !socket) return;

        function handleOpenPopup() {
            setCanShowPopup(true);

            setTimeout(() => {
                setCurrPopupIndex(0);
            }, 1500);
        }

        socket.on('broadcastTimeChange', handleOpenPopup);

        return () => {
            socket.off('broadcastTimeChange', handleOpenPopup);
        };
    }, [socket, canShowPopup]);


    const onQuestionChange = (data: {
        isCorrect: boolean
    }) => {
        setQuestionStatus("neutral");
        setTimeout(() => {
            if (data.isCorrect) {
                console.log("within LL, CQI is: ", currQuestionIndex, " and marking currQ as completed");
                setQuestionStatus("correct");
            } else {
                setQuestionStatus("incorrect");
            }
        }, 100);

    };

    useEffect(() => {
        function leaderActionListener(action: string) {
            console.log("leaderAction is: ", action);
            if (action === "closeInstructions") {

            }
        }


        eventEmitter.on('leaderAction', leaderActionListener);

        return () => {
            eventEmitter.off('leaderAction', leaderActionListener);
        };

    }, []);


    useEffect(() => {
        if (currQuestionIndex === questions.length) {
            onComplete();
        }

    }, [currQuestionIndex]);

    // emit event for changing answer selection for current question

    const handleChangeAnswer = (event: ChangeEvent<HTMLInputElement>) => {
        if (questionStatus !== 'correct') {
            setSelectedAnswer((event.target as HTMLInputElement).value);
        }
    }

    function handleGoToNextQuestion() {
        onQuestionIndexUpdate(currQuestionIndex + 1);
        setSelectedAnswer("");
        setQuestionStatus("neutral");
    }

    const handleExplanationClose = () => {
        setExplanationOpen(false);
    };

    // when an answer is submitted:
    // - increment attempts used on that question by 1
    // - emit event with attempts used changes
    // - if answer is correct, emit score increment events according to scoring scheme
    // - emit event to change question status based on whether submission is correct or not
    const handleSubmitAnswer = async (selectedAnswer: string) => {
        if (!isLeader || selectedAnswer === "") return;

        updateVoteMessage(`${leaderName} has selected ${selectedAnswer.at(0)}.`);

        let isCorrect = false;

        console.log("selected answer is: ", selectedAnswer, " and correct answer is: ", answers[currQuestionIndex]);

        if (answers[currQuestionIndex] === selectedAnswer) isCorrect = true;

        onQuestionChange({isCorrect});
    }

    function handlePopupClose() {
        const nextIndex = currPopupIndex + 1;
        setCurrPopupIndex(-1);
        setTimeout(() => {
            setCurrPopupIndex(nextIndex);
        }, 1000);
    }


    const submitButton = (
        <Button
            variant="contained"
            color="generic"
            sx={{
                minWidth: '75px',
                width: '25vw',
                height: '5vh'
            }}
            onClick={
                questionStatus === "correct"
                    ? handleGoToNextQuestion
                    : () => handleSubmitAnswer(selectedAnswer)
            }
        >
            {questionStatus === "correct" ? "Continue!" : "Submit"}
        </Button>
    );

    // and more here

    const displayedQuestionIndex = Math.min(currQuestionIndex, questions.length - 1);

    return (
        <ThemeProvider theme={theme}>
            {musicSrcList && <MusicPlayer musicSrcList={musicSrcList} startPlay={!isInstructionPopupOpen} />}
            {currPopupIndex >= 0 && currPopupIndex < popups.length && (
                <Box sx={{position: 'relative', width: '100%', height: '100%'}}>
                    <Tooltip
                        sx={{
                            maxWidth: '60px',
                            transition: "opacity 0.5s ease, transform 0.5s ease",
                            transform: questionStatus !== "neutral" ? "translateY(0)" : "translateY(-10px)",
                            borderRadius: '20px'
                        }}
                        open={true}
                        title={
                            <PopupBody>
                                <Typography variant="body2" gutterBottom>
                                    {popups[currPopupIndex].message}
                                </Typography>
                                <CloseButton variant="contained" onClick={handlePopupClose}>
                                    Got it!
                                </CloseButton>
                            </PopupBody>
                        }
                        arrow placement='top'
                    >
                        <InvisibleAnchor
                            top={popups[currPopupIndex].anchorPosition.top}
                            right={popups[currPopupIndex].anchorPosition.right}
                        />
                    </Tooltip>
                </Box>
            )}

            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <Container maxWidth={false} sx={{maxHeight: 'none', paddingTop: '10px'}}>
                    <Box display='flex' flexDirection={"column"} alignItems={"center"} gap={5}>
                        <Box display='flex' justifyContent={'center'} gap={10}>
                            <Typography
                                variant="h5">Scenario {displayedQuestionIndex + 1} / {questions.length}</Typography>
                            <Typography variant="h5">Score: {gameScore}</Typography>
                        </Box>
                        <Grid display='flex' container alignItems="flex-start" gap={5} justifyContent='center'>
                            {/* 'question area' - contains question and radio button group for options */}
                            <Grid item xs={4} display='flex' flexDirection="column" gap={2}>
                                <Typography overflow={"auto"}>{questions[displayedQuestionIndex]}</Typography>
                                <RadioGroup name="answers-group" value={selectedAnswer} onChange={handleChangeAnswer}
                                            sx={{gap: 2}}>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQuestionIndex)!.length >= 1 ? 'visible' : 'hidden'}}
                                        value="A" control={<Radio/>} label={options.at(displayedQuestionIndex)!.at(0)}/>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQuestionIndex)!.length >= 2 ? 'visible' : 'hidden'}}
                                        value="B" control={<Radio/>} label={options.at(displayedQuestionIndex)!.at(1)}/>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQuestionIndex)!.length >= 3 ? 'visible' : 'hidden'}}
                                        value="C" control={<Radio/>} label={options.at(displayedQuestionIndex)!.at(2)}/>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQuestionIndex)!.length >= 4 ? 'visible' : 'hidden'}}
                                        value="D" control={<Radio/>} label={options.at(displayedQuestionIndex)?.at(3)}/>
                                </RadioGroup>
                                {currPopupIndex === popups.length ? (
                                    <Tooltip
                                        sx={{
                                            maxWidth: '200px',
                                            transition: "opacity 0.5s ease, transform 0.5s ease",
                                            transform: questionStatus !== "neutral" ? "translateY(0)" : "translateY(-10px)",
                                            borderRadius: '20px'
                                        }}
                                        open={true}
                                        title={
                                            <PopupBody>
                                                <Typography variant="body2" gutterBottom>
                                                    {"As you are currently the leader, please select your answer, and press here to submit!"}
                                                </Typography>
                                                <CloseButton variant="contained" onClick={handlePopupClose}>
                                                    Got it!
                                                </CloseButton>
                                            </PopupBody>
                                        }
                                        arrow
                                        placement="top"
                                    >
                                        {submitButton}
                                    </Tooltip>
                                ) : (
                                    submitButton
                                )}
                            </Grid>
                            <Box display='flex' alignItems="center" justifyContent='center' width='450px'
                                 height='350px'>
                                <figure style={{textAlign: 'center'}}>
                                    <img src={images[displayedQuestionIndex]} alt="Suspects"
                                         style={{
                                             maxWidth: '450px',
                                             maxHeight: '350px',
                                             objectFit: 'contain'
                                         }}
                                    />
                                </figure>
                            </Box>
                        </Grid>
                        <Alert variant={"outlined"} severity={questionStatus === "correct" ? "success" : "error"}
                               style={{
                                   visibility: questionStatus !== "neutral" ? "visible" : "hidden",
                                   transition: "opacity 0.5s ease, transform 0.5s ease",
                                   transform: questionStatus !== "neutral" ? "translateY(0)" : "translateY(-10px)",
                               }}>
                            {questionStatus === "correct" ?
                                <div>
                                    <Typography style={{paddingBottom: 2}}>Correct!</Typography>
                                    <Typography fontSize={'12px'}>{whys[displayedQuestionIndex]}</Typography>
                                </div>
                                : <Typography>Incorrect - please try again.</Typography>}
                        </Alert>
                    </Box>
                </Container>
                <Dialog open={explanationOpen} onClose={handleExplanationClose}>
                    <DialogContent>
                        {questionStatus !== 'neutral' && (
                            <Typography variant="h6"
                                        sx={{color: questionStatus === 'correct' ? 'green' : 'red', mb: 2}}>
                                {questionStatus === 'correct' ? 'Correct!' : 'Incorrect!'}
                            </Typography>
                        )}
                        <Typography>{whys[displayedQuestionIndex]}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleExplanationClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </ThemeProvider>
    );
};

export default TutorialRoom;
