import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSocket} from "../contexts/SocketContext";
import {Alert, Box, Snackbar} from "@mui/material";
import {useTeam} from "../contexts/TeamContext";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "../styling/palette";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import GameHeader from "../components/GameHeader";
import Logos from "../components/Logos";
import UnanimousYesPopup from "../components/UnanimousYesPopup";
import InstructionPopup from "../components/InstructionPopup";
import GameCompleteDialog from "../components/GameCompleteDialog";
import axios from "axios";
import eventEmitter from "../contexts/EventEmitter";
import GameRenderer from "./GameRenderer";
import {InstructionsType} from "../lobbies/types";

const correctSFX = require('../assets/AnswerCorrect.mp3');
const incorrectSFX = require('../assets/AnswerIncorrect.mp3');
const buttonSFX = require('../assets/ButtonPressSFX.mp3');


const BaseRoom: React.FC = () => {
    const navigate = useNavigate();

    const {socket} = useSocket();
    const {teamName, leaderName, isLeader, currGame,gameStarted, setCurrGame, setGameStarted} = useTeam();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [voteMessage, setVoteMessage] = useState('');
    const [isVotePopupOpen, setIsVotePopupOpen] = useState(false);
    const [voteResult, setVoteResult] = useState<'neutral' | 'confirmed' | 'rejected'>('neutral');

    const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(true);
    const [instructions, setInstructions] = useState<InstructionsType>({
        title: 'Room Instructions',
        subtitle: 'Welcome to the room! Here are the instructions you need to follow:',
        content: [],
    });

    const [gameScore, setGameScore] = useState(0);
    const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);

    const [facilitatorAlerted, setFacilitatorAlerted] = useState(false);

    const [timerCanCount, setTimerCanCount] = useState(false);
    const [timeLimit, setTimeLimit] = useState(10 * 60);
    const halfTimeAlertShownRef = useRef(false);
    const sixtySecondAlertShownRef = useRef(false);

    const [currQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const [onCompleteMessage, setOnCompleteMessage] = useState('');
    const [finalAlertOpen, setFinalAlertOpen] = useState(false);

    const correctRef = useRef<HTMLAudioElement>(new Audio(correctSFX));
    const incorrectRef = useRef<HTMLAudioElement>(new Audio(incorrectSFX));
    const buttonPressRef = useRef<HTMLAudioElement>(new Audio(buttonSFX));


    useEffect(() => {
        if (!socket) return;
        socket.emit('checkGameStatus', (response: { gameStarted: boolean; }) => {
            if (response.gameStarted) {
                setGameStarted(true);
            } else if (currGame !== 0) {
                console.log("game not started, navigating back to lobby");
                navigate('/lobby');
            }
        });
    }, []);


    useEffect(() => {
        if (!socket) return;
        console.log("syncing with database");
        socket.emit('game-state', (state: {
            currQuestionIndex: number,
            gameScore: number,
            currGame: number,
            shouldVotePopupOpen: boolean
        }) => {
            console.log("the cqi, score, and game is: ", state.currQuestionIndex, ", ", state.gameScore, ", ", state.currGame);
            setCurrentQuestionIndex(state.currQuestionIndex);
            setGameScore(state.gameScore);
            if (gameStarted) setCurrGame(state.currGame);
            if (state.shouldVotePopupOpen) {
                setIsInstructionPopupOpen(false);
            }
            setIsVotePopupOpen(state.shouldVotePopupOpen);
        });
    }, [socket]);


    useEffect(() => {
        if (!socket) return;

        socket.on('broadcastMessage', (data) => {
            setSnackbarMessage(data.message);
            setSnackbarOpen(true);
        });

        socket.on('updateVoteMessage', (data) => {
            console.log("received data back from server: ", data);
            setVoteMessage(data);
        });


        socket.on('startTimer', () => {
            console.log("setting timer can count to be true");
            setTimerCanCount(true);
        });

        socket.on("leaderActionSubmitted", () => {
            setIsVotePopupOpen(true); // Open the popup for team members
        });

        socket.on('leaderAction', (data: { action: string, data?: any }) => {
            console.log("received leaderAction back in the BaseRoom from server, data is: ", data);
            const {action, data: actionData} = data;
            if (actionData) {
                eventEmitter.emit('leaderAction', action, actionData);
            } else {
                eventEmitter.emit('leaderAction', action);
            }
        });

        return () => {
            socket.off('broadcastMessage');
            socket.off('updateVoteMessage');
            socket.off('startTimer');
            socket.off('leaderActionSubmitted');
            socket.off('leaderAction');
        };

    }, [socket, isLeader]);

    useEffect(() => {
        if (!socket) return;
        socket.on('teamDecision', async (data) => {
            const {decision} = data;

            if (decision) {
                const {isCorrect, gameScore: newGameScore, currQuestionIndex} = data;

                if (isLeader) {
                    await updateScore(newGameScore - gameScore);
                }

                setVoteResult("confirmed")
                evalDecision(isCorrect, newGameScore, currQuestionIndex);
            } else {
                setVoteResult("rejected");
            }

            setTimeout(() => {
                setVoteResult("neutral");
                setIsVotePopupOpen(false);
            }, 250);
        });

        return () => {
            socket.off('teamDecision');
        };

    }, [socket, isLeader, gameScore]);

    function handleTimeUpdate(timeLeft: number) {

        if (timeLeft <= (timeLimit) / 2 && !halfTimeAlertShownRef.current) {
            setSnackbarMessage('Half the time remaining!');
            setSnackbarOpen(true);
            halfTimeAlertShownRef.current = true;
        }

        if (timeLeft <= 60 && !sixtySecondAlertShownRef.current) {
            setSnackbarMessage('60 seconds remaining!');
            setSnackbarOpen(true);
            sixtySecondAlertShownRef.current = true;
        }
    }

    async function handleInstructionClose() {
        setIsInstructionPopupOpen(false);
        setTimerCanCount(true);

        if (currGame === 0) {
            eventEmitter.emit('leaderAction', 'closeInstructions');
        }
    }

    function updateVoteMessage(message: string) {
        if (!socket) return;
        socket.emit('updateVoteMessage', message);
    }

    function getLeader() {
        return leaderName;
    }

    function handleAlertFacilitator() {
        if (!socket) return;
        socket.emit('alertFacilitator', {teamName});
        setFacilitatorAlerted(true);
        setTimeout(() => {
            setFacilitatorAlerted(false);
        }, 5000);
    }

    async function updateDatabaseQuestionIndex(newIndex: number) {
        try {
            await axios.post(`/api/update-game-index`, {
                teamName: teamName,
                currQuestionIndex: newIndex,
            });

        } catch (e) {
            console.error("couldn't update score: ", e);
        }

    }

    async function updateGameDetails(scoreChange: number): Promise<any> {
        let newGameDetails = null;
        try {
            const response = await axios.post(`/api/update-game-details`, {
                teamName: teamName,
                score: scoreChange,
            });

            let {gameDetails} = response.data;
            newGameDetails = gameDetails


        } catch (e) {
            console.error("couldn't update score: ", e);
        }

        return newGameDetails;

    }

    function playButtonAudio() {
        buttonPressRef.current.currentTime = 0;
        buttonPressRef.current.play().catch((error) => {
            console.error("Failed to play button sound:", error);
        });
    }

    async function handleSubmit(isCorrect: boolean, scoreChange: number) {

        console.log("handling submit in base room. Score change is: ", scoreChange);
        if (!socket || !isLeader) return;

       playButtonAudio();

        const team_size = await getTeamSize();
        console.log("team size is: ", team_size);

        if (team_size === 1) {
            await updateScore(scoreChange);
            const gameDetails = await updateGameDetails(scoreChange);
            const gameScore = gameDetails.gameScore;
            evalDecision(isCorrect, gameScore, currQuestionIndex);
        } else {
            socket.emit('leaderActionSubmit', {
                isCorrect,
                scoreChange
            })
        }
    }


    function evalDecision(isCorrect: boolean, newGameScore: number, currQuestionIndex: number) {
        if (isCorrect) {
            setGameScore(newGameScore);
            setCurrentQuestionIndex(currQuestionIndex);

            correctRef.current.currentTime = 0;
            correctRef.current.volume = 0.5;
            correctRef.current.play().catch((error) => {
                console.error("Failed to play correctSFX sound:", error);
            });

        } else {
            incorrectRef.current.currentTime = 0;
            incorrectRef.current.play().catch((error) => {
                console.error("Failed to play incorrectSFX sound:", error);
            });

        }

        eventEmitter.emit('questionChange', {isCorrect});

    }

    async function handleQuestionIndexUpdate(newIndex: number) {

        console.log("setting QuestionIndex to: ", newIndex);
        setCurrentQuestionIndex(newIndex); // Update the state based on child-provided index

        if (isLeader) {
            await updateDatabaseQuestionIndex(newIndex);
        }
    }

    async function getTeamSize() {
        try {
            const response = await axios.get(`/api/team-in-game`, {
                params: {
                    teamName: teamName
                }
            });

            console.log("response from server is: ", response.data);
            const {team_size_response} = response.data;

            return team_size_response;
        } catch (e) {
            console.error("couldn't get teamSize: ", e);
        }
    }

    async function updateScore(scoreChange: number) {
        if (!socket) return;
        socket.emit('updateScore', scoreChange);
    }

    async function onLeaderAction(action: string, data?: any) {
        console.log("Got leader action in the BaseRoom: ", action, " , data is: ", data);
        if (!socket) return;

        if (data) {
            socket.emit('leaderAction', {action, data});
        } else {
            socket.emit('leaderAction', {action});
        }
    }


    function onTimeUp() {
        setTimerCanCount(false);
        endGame();
    }

    function endGame() {
        setFinalAlertOpen(true);
    }

    function onCloseLastDialog(success: boolean) {
        setFinalAlertOpen(false);

        if (!socket) return;

        const sid = socket.id;
        axios.put('/api/update-room', {
            sid: sid,
            room: -1,
            teamName: teamName
        }).then((response) => {
            if (response.data.success) {
                console.log('Room updated successfully');
            } else {
                console.log('Error updating room');
            }
        }).catch((error) => {
            console.error('Error updating room:', error);
        });


        if (success && currGame > 0) {
            navigate('/game-end');
        } else {
            navigate('/lobby');
        }
        setCurrGame(currGame + 1);
    }


    return (
        <ThemeProvider theme={theme}>
            <DndProvider backend={HTML5Backend}>
                <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                    <GameHeader
                        isLeader={isLeader}
                        setIsInstructionPopupOpen={setIsInstructionPopupOpen}
                        handleAlertFacilitator={handleAlertFacilitator}
                        refreshLeaderboard={refreshLeaderboard}
                        alerted={facilitatorAlerted}
                        timerCanCount={timerCanCount}
                        teamName={teamName}
                        TIME_LIMIT={timeLimit}
                        handleTimeUpdate={handleTimeUpdate}
                        onTimeUp={onTimeUp}
                    />

                    <GameRenderer
                        currGame={currGame}
                        isInstructionPopupOpen={isInstructionPopupOpen}
                        setInstructions={setInstructions}
                        setOnCompleteMessage={setOnCompleteMessage}
                        setTimeLimit={setTimeLimit}
                        isLeader={isLeader}
                        leaderName={leaderName}
                        currQuestionIndex={currQuestionIndex}
                        gameScore={gameScore}
                        updateVoteMessage={updateVoteMessage}
                        handleSubmit={handleSubmit}
                        onLeaderAction={onLeaderAction}
                        handleQuestionIndexUpdate={handleQuestionIndexUpdate}
                        endGame={endGame}
                    />


                    <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto'}}>
                        <Logos/>
                    </Box>
                    <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                              open={snackbarOpen}
                              autoHideDuration={6000}
                              onClose={() => setSnackbarOpen(false)}
                              sx={{
                                  minWidth: '500px',
                                  minHeight: '120px',
                              }}
                    >
                        <Alert onClose={() => setSnackbarOpen(false)}
                               severity="info"
                               sx={{
                                   width: '100%',
                                   fontSize: '1.4rem'
                               }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>

                {isVotePopupOpen && socket && (
                    <UnanimousYesPopup
                        open={isVotePopupOpen}
                        voteMessage={voteMessage}
                        voteResult={voteResult}
                        sid={socket.id || ""}
                    />
                )}

                <InstructionPopup
                    open={isInstructionPopupOpen}
                    onClose={handleInstructionClose}
                    boldText={isLeader ? `You are the leader this round!` : `The team leader this round is: ${getLeader()}`}
                    instructions={instructions}
                />
                <GameCompleteDialog open={finalAlertOpen} onClose={onCloseLastDialog} success={timerCanCount}
                                    message={onCompleteMessage} currGame={currGame} score={gameScore}/>

            </DndProvider>

        </ThemeProvider>
    );
}

export default BaseRoom;