import React, {useState, useEffect, useRef} from 'react';
import {
    TextField,
    Button,
    Snackbar,
    Alert,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import {useSocket} from '../contexts/SocketContext';
import {theme} from '../styling/palette';
import {ThemeProvider} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import alertBell from '../assets/Siren.svg';
import alertYellow from '../assets/SirenYellow.svg';

const dingSound = require('../assets/ding.mp3');

interface AlertHistoryItem {
    team: string;
    message: string;
    timestamp: string;
}

interface TeamMember {
    username: string;
    currGame: number;
}

interface TeamInfo {
    teamName: string;
    leader: string;
    members: TeamMember[];
    alertStatus: 'none' | 'alerted' | 'assisting';
}


const gameMap: { [key: number]: string } = {
    [-1]: 'Lobby',
    0: 'Tutorial',
    1: 'Policy',
    2: 'Hygiene',
    3: 'Phantom',
    4: 'Lockdown',
    5: 'Insider',
    6: 'Finale'
};

const FacilitatorDashboard: React.FC = () => {
    const [message, setMessage] = useState<string>('');
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [confirm, setConfirm] = useState<boolean>(false);
    const {socket} = useSocket();
    const socketRef = useRef(socket);
    const navigate = useNavigate();

    const [teamInformation, setTeamInformation] = useState<TeamInfo[]>([]);

    const [alertHistory, setAlertHistory] = useState<AlertHistoryItem[]>([]);
    const [alertHistoryHovered, setAlertHistoryHovered] = useState(false);
    const [timeUpdateTrigger, setTimeUpdateTrigger] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeUpdateTrigger((prev) => prev + 1);

        }, 5000); // Update alert history time text every 5 seconds

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {

        fetchTeamInformation();

        const intervalId = setInterval(() => {
            fetchTeamInformation();
        }, 10000);

        return () => {
            clearInterval(intervalId);
        };
    }, [socket]);

    const playDing = async () => {
        const audio = new Audio(dingSound);
        await audio.play();
    };

    useEffect(() => {
        if (!socket) return;
        const token = localStorage.getItem('jwt');
        if (!token) return;

        socket.on('teamInformation', (teamInfo) => {
            console.log("teamInfo updated: ", teamInfo);
            if (Array.isArray(teamInfo)) setTeamInformation(teamInfo);
        });

        socket.on('broadcastMessage', (data: { message: string }) => {
            setSnackbarMessage(data.message);
            setSnackbarOpen(true);
        });

        socket.on('navigateToFinale', () => {
            navigate('/finale-game');
        });

        socket.on('facilitatorAlert', async (data: { message: string, teamName: string }) => {
            setSnackbarMessage(data.message);
            setSnackbarOpen(true);
            await playDing();
            const newAlert = {
                team: data.teamName,
                message: data.message,
                timestamp: new Date().toISOString()
            };
            setAlertHistory((prevHistory) => [newAlert, ...prevHistory]);
        });

        socketRef.current = socket;
        return () => {
            socket.off('teamInformation');
            socket.off('broadcastMessage');
            socket.off('facilitatorAlert');
            socket.off('navigateToFinale');
        };
    }, [socket]);

    function fetchTeamInformation() {
        if (!socket) return;
        socket.emit('teamInformation');
    }

    const handleSendMessage = () => {
        if (message.trim() && socket) {
            socket.emit('broadcastMessage', {target: 'all', message});
            setMessage('');
        }
    };

    function updateAlertStatus(teamName: string, alertStatus: string) {
        if (!socket) return;
        socket.emit('facilitatorResolveAlert', {teamName, alertStatus});
    }

    function handleStartGame() {
        if (!socket) return;
        socket.emit('startGame');
    }

    const handleChangeTeamLeader = (teamName: string) => {
        if (socket) {
            socket.emit('randomSelectFac', {teamName: teamName});
            setTimeout(() => {
                fetchTeamInformation();
            }, 750);

        }
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleWipeData = () => {
        if (socket) {
            socket.emit('resetButton');
        }
        setConfirm(false);
    }

    const handleOpenConfirm = () => {
        setConfirm(true);
    }

    const handleCloseConfirm = () => {
        setConfirm(false);
    }

    function formatTimeElapsed(timestamp: string | number | Date) {
        const now = new Date();
        console.log("now is: ", now);
        console.log("timestamp: ", timestamp, " and the type is: ", typeof(timestamp));
        const alertTime = new Date(timestamp);
        console.log("alertTime: ", alertTime);
        const diffInSeconds = Math.floor((now.getTime() - alertTime.getTime()) / 1000);
        console.log("diffInSeconds: ", diffInSeconds);

        if (diffInSeconds < 5) {
            return 'just now';
        } else if (diffInSeconds < 60) {
            return `${diffInSeconds} secs ago`;
        } else if (diffInSeconds < 3600) {
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            if (diffInMinutes === 1) {
                return `${diffInMinutes} min ago`;
            } else return `${diffInMinutes} mins ago`;
        } else {
            const diffInHours = Math.floor(diffInSeconds / 3600);
            return `${diffInHours} hrs ago`;
        }
    }

    const handleNavigateToFinale = () => {
        if (socketRef.current) {
            socketRef.current.emit('navigateToFinale');
        }
    }

    const handleNavigateToPodium = () => {
        if (socketRef.current) {
            socketRef.current.emit('navigateToEndGame');
        }
    }
    function getLeader(team: TeamInfo) {
        if (team.leader) return team.leader;

        for (const member of team.members) {
            if (member.currGame === 0) {
                return "None in Tutorial Room";
            }
        }

        return "Couldn't get leader";


    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1, mt: '2vh', mb: '4vh', mx: '2vw'}}>
                {/*Top portion*/}
                <Box sx={{display: 'flex'}}>
                    {/*Facilitator controls*/}
                    <Box sx={{
                        flex: alertHistoryHovered ? 0.7 : 1,
                        transition: 'flex 0.2s',
                        mr: 1
                    }}>
                        <Typography variant="h6">Facilitator Dashboard</Typography>
                        <TextField
                            data-cy="broadcast-message"
                            label="Broadcast Message to All Teams"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            sx={{my: 2, width: '100%'}}
                            InputProps={{
                                sx: {
                                    borderRadius: '12px' // Adjust the value for more or less rounding
                                }
                            }}
                        />
                        <Box sx={{display: 'flex', gap: 2}}>
                            <Button onClick={handleSendMessage} variant="contained" color="generic"
                                    data-cy="send-message-button">
                                Send Message
                            </Button>
                            <Button onClick={handleStartGame} variant="contained" color="generic"
                                    data-cy="start-game-button">
                                Start Game
                            </Button>
                        </Box>
                    </Box>
                    {/*Alert history*/}
                    <Box sx={{
                        flex: alertHistoryHovered ? 1.3 : 1,
                        transition: 'flex 0.2s',
                        ml: 2,
                        mr: 3,
                        position: 'relative',
                        height: '150px', // Fixed height when not hovered
                        overflow: 'visible',
                    }}
                         onMouseEnter={() => setAlertHistoryHovered(true)}
                         onMouseLeave={() => setAlertHistoryHovered(false)}>
                        <Typography variant="h6">Alert History</Typography>
                        <Box
                            sx={{
                                position: 'absolute',
                                height: alertHistoryHovered ? '350px' : '150px',
                                width: '100%',
                                backgroundColor: 'white',
                                border: '1px solid black',
                                borderRadius: '12px',
                                overflowY: 'auto',
                                my: 2,
                                p: 1,
                                transition: 'height 0.2s',
                                zIndex: 1,
                            }}
                        >
                            <List>
                                {alertHistory.map((alert, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {`${alert.team} - ${alert.message}`}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {formatTimeElapsed(alert.timestamp)}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Box>
                </Box>
                {/*Team Lists and Buttons*/}
                <Box>
                    <Grid container spacing={2} sx={{mt: 4, minHeight: '200px'}}>
                        <Grid item xs={10}>
                            <Typography variant="h6">Team Lists</Typography>
                            <List>
                                {teamInformation.map((team: TeamInfo, index) => (
                                    <ListItem key={index} sx={{display: 'flex', alignItems: 'center', height: '80px'}}>
                                        <ListItemText primary={team.teamName} sx={{minWidth: '100px'}}/>
                                        <Button
                                            variant="contained"
                                            color="generic"
                                            onClick={() => handleChangeTeamLeader(team.teamName)}
                                            data-cy={`change-team-leader-${team.teamName}`}
                                            sx={{
                                                width: '100%',
                                                minWidth: '200px',
                                                maxWidth: '40vw',
                                                position: 'relative',
                                                overflow: 'hidden',
                                                padding: '8px 16px',
                                                transition: 'background-color 0.2s',
                                                '&:hover': {
                                                    backgroundColor: theme.palette.generic.light,
                                                },
                                                '& .normalText': {
                                                    opacity: 1,
                                                    transition: 'opacity 0.2s',
                                                },
                                                '& .hoverText': {
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    opacity: 0,
                                                    transition: 'opacity 0.2s',
                                                },
                                                // On hover, normal text fades out and hover text fades in
                                                '&:hover .normalText': {
                                                    opacity: 0,
                                                },
                                                '&:hover .hoverText': {
                                                    opacity: 1,
                                                },
                                            }}
                                        >
                                            <span className="normalText">{`Leader: ${getLeader(team)}`}</span>
                                            <span className="hoverText">Click to Change</span>
                                        </Button>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                cursor: team.alertStatus !== 'none' ? 'pointer' : 'default',
                                                ml: '12px'
                                            }}
                                            onClick={() => {
                                                if (team.alertStatus !== 'none') {
                                                    const newStatus = team.alertStatus === 'alerted' ? 'assisting' : 'none';
                                                    updateAlertStatus(team.teamName, newStatus);
                                                }
                                            }}
                                        >

                                            <Box
                                                component="img"
                                                src={team.alertStatus === 'assisting' ? alertYellow : alertBell}
                                                alt="Alert Bell"
                                                sx={{
                                                    width: 45,
                                                    height: 45,
                                                    filter: team.alertStatus === 'none' ? 'grayscale(100%)' : 'none'
                                                }}
                                            />
                                            <Typography variant="body1"
                                                        sx={{
                                                            fontSize: '8px',
                                                            color:
                                                                team.alertStatus === 'none' ? '#6D6E71' :
                                                                    team.alertStatus === 'assisting' ? '#C5C745' : '#DA1C5C',
                                                            fontWeight: 550,
                                                            width: '60px',
                                                            textAlign: 'center'
                                                        }}>
                                                {team.alertStatus === 'none' ? '' :
                                                    team.alertStatus === 'assisting' ? 'Click to Resolve' : 'Click to Address'}
                                            </Typography>
                                        </Box>
                                        <Typography variant="body2" sx={{
                                            ml: 2, height: '40px',
                                            width: '240px',
                                            color: 'black'}}
                                        >
                                            {team.alertStatus === 'alerted' ? `${team.teamName} requested help!` :
                                                team.alertStatus === 'assisting' ? 'A facilitator is currently assisting this team.' : ''}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                    <Button onClick={handleOpenConfirm}
                            data-cy="reset-database-button">{"Reset Database"}</Button>
                    <Button onClick={handleNavigateToFinale} variant="contained" color="generic" sx={{mr: '10px'}}
                            data-cy="navigate-finale-button">
                        Navigate to Finale
                    </Button>
                    <Button onClick={handleNavigateToPodium} variant="contained" color="generic" data-cy="navigate-endgame-button">
                        Navigate to Podium
                    </Button>
                </Box>
                {/*Player Information*/}
                <Box
                    sx={{
                        border: '1px solid black',           // Outline border
                        borderRadius: '12px',                // Rounded corners
                        p: 2,                                 // Padding inside the box
                        mt: 4,                                // Margin top to separate from above elements
                        backgroundColor: 'white',             // Background color
                        minHeight: '200px',                      // Fixed height
                        overflowY: 'auto',                    // Scroll vertically if content overflows
                    }}
                >

                    <Box>
                        {teamInformation.map((team: TeamInfo, index) => (
                            <Box key={index} sx={{ mb: 2 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    {team.teamName}
                                </Typography>
                                <Typography variant="body2">
                                    {team.members
                                        .map(member => `${member.username} (${gameMap[member.currGame]})`)
                                        .join(', ')}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Snackbar open={snackbarOpen} autoHideDuration={null} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} onClose={handleCloseSnackbar} data-cy="snackbar">
                    <Alert onClose={handleCloseSnackbar} severity="info" sx={{width: '100%'}}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <Dialog open={confirm} data-cy="confirm-reset-dialog">
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">This will reset all teams' points and players, which may cause issues if
                        the game is still being played.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} color="generic" data-cy="cancel-reset-button">Cancel</Button>
                    <Button onClick={handleWipeData} color="generic" data-cy="confirm-reset-button">Yes</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default FacilitatorDashboard;
