import React, {useState, useEffect, useCallback, useRef} from 'react';
import eventEmitter from "../../contexts/EventEmitter";
import {ThemeProvider} from '@mui/material/styles';
import {Box, Container, Grid, Paper, Typography} from '@mui/material';
import {theme} from '../../styling/palette';
import LinearProgressWithLabel from "./components/LinearProgressWithLabel";
import CardComponent from "./components/CardComponent";
import Bucket from './components/Bucket';
import {GameProps} from "../types";

import p1 from './img/p1.png';
import p2 from './img/p2.png';
import p3 from './img/p3.png';
import p4 from './img/p4.png';
import p5 from './img/p5.png';
import p6 from './img/p6.png';
import p7 from './img/p7.png';
import p8 from './img/p8.png';
import p9 from './img/p9.png';
import p10 from './img/p10.png';
import p11 from './img/p11.png';
import p12 from './img/p12.png';
import p13 from './img/p13.png';
import p14 from './img/p14.png';
import p15 from './img/p15.png';
import p16 from './img/p16.png';
import p17 from './img/p17.png';
import p18 from './img/p18.png';
import p19 from './img/p19.png';
import p20 from './img/p20.png';
import p21 from './img/p21.png';
import {Card} from "../types";
import {throttle} from "lodash";
import MusicPlayer from "../../components/MusicPlayer";

const Policy1 = require('../../assets/Policy.mp3');
const Policy2 = require('../../assets/Policy2.mp3');

const musicSrcList: string[] = [Policy1, Policy2];


const letterMap: { [key: string]: string } = {
    "Cloud Services Policy": "A",
    "Email and Communication Policy": "B",
    "Acceptable Use Policy": "C",
    "Information Classification Handling": "D",
    "Physical Security Policy": "E",
    "Social Media Policy": "F"
}


const cards: Card[] = [
    {
        id: 1,
        text: "Employees' personal cloud services accounts must not be used for processing Company ABC information.",
        policy: 'Cloud Services Policy',
        img: p1
    },
    {
        id: 2,
        text: 'Users must not use their personal email (e.g. janedoe@hotmail.com) or any other personal electronic communication accounts (e.g. Facebook, Whatsapp, Skype) to transmit Company ABC information.',
        policy: 'Email and Communication Policy',
        img: p2
    },
    {
        id: 3,
        text: 'Changes to the original scope of the acquisition must be reviewed by the vendor risk management team prior to being implemented, and managed via the change management process.',
        policy: 'Cloud Services Policy',
        img: p3
    },
    {
        id: 4,
        text: 'All users are responsible and accountable for using social media (both professionally and personally) in a manner that will not tarnish or degrade Company ABC\'s reputation. Users must not disclose confidential or proprietary Company ABC information, or information about Company ABC\'s customers (including customer names that are not publicly available on the Company ABC corporate website).',
        policy: 'Social Media Policy',
        img: p4
    },
    {
        id: 5,
        text: 'Only Company ABC computing resources can be used to access Customer Data and/or Company ABC\'s data centers. Personal devices must not be used to connect to Company ABC\'s data centers.',
        policy: 'Acceptable Use Policy',
        img: p5
    },
    {
        id: 6,
        text: 'Employees should wear their access card on their outer garments to clearly identify themselves as employees when inside Company ABC offices.',
        policy: 'Physical Security Policy',
        img: p6
    },
    {
        id: 7,
        text: 'Information that is not classified explicitly is "Confidential" by default.',
        policy: 'Information Classification Handling',
        img: p7
    },
    {
        id: 8,
        text: 'Users must not discuss confidential company information outside of Company ABC offices and/or with Company ABC teams that are not authorized to access such information.',
        policy: 'Acceptable Use Policy',
        img: p8
    },
    {
        id: 9,
        text: 'If a file containing Restricted information is authorized for external distribution, it must be protected from modification and printing whenever possible and technically feasible.',
        policy: 'Information Classification Handling',
        img: p9
    },
    {
        id: 10,
        text: 'Employees must not sign-up for or agree to Terms of Service or other click-through agreements for any cloud services unless it has been reviewed and processed.',
        policy: 'Cloud Services Policy',
        img: p10
    },
    {
        id: 11,
        text: 'All email, electronic files, and information created, sent, and/or recieved using Company ABC\'s systems and networks are considered Company ABC property.',
        policy: 'Email and Communication Policy',
        img: p11
    },
    {
        id: 12,
        text: 'Users must exercise good judgement regarding the reasonable personal use of Company ABC computing resources. Personal use is permitted on a limited or incidental basis (e.g. during work breaks) provided it does not interfere with or impact Company ABC\'s business operations and work quality / productivity.',
        policy: 'Acceptable Use Policy',
        img: p12
    },
    {
        id: 13,
        text: 'Avoid inputting Company ABC data or information classified as "Restricted" or "Confidential" into AI systems.',
        policy: 'Acceptable Use Policy',
        img: p13
    },
    {
        id: 14,
        text: 'Each person must only use their own access card to enter controlled doors within Company ABC offices. The sharing of access cards and tailgating is prohibited.',
        policy: 'Physical Security Policy',
        img: p14
    },
    {
        id: 15,
        text: 'Company ABC laptops must be secured to prevent unauthorized access, theft and damages (e.g. locked in file cabinets and desks) when they are no longer used at the end of the day.',
        policy: 'Physical Security Policy',
        img: p15
    },
    {
        id: 16,
        text: 'Integration between new and existing cloud services, including the installation and use of plugins and APIs, must not be implemented without review and authorization via Company ABC\'s vendor management process',
        policy: 'Cloud Services Policy',
        img: p16
    },
    {
        id: 17,
        text: 'Users must not store Company ABC information on third-party storage locations (e.g. personal Dropbox) that have not been approved by Company ABC.',
        policy: 'Acceptable Use Policy',
        img: p17
    },
    {
        id: 18,
        text: 'Employees must take reasonable steps to protect their access cards to prevent unauthorized access and misuse. Stolen or lost access cards must be reported to Facilities as soon as possible.',
        policy: 'Physical Security Policy',
        img: p18
    },
    {
        id: 19,
        text: 'Users must use extreme caution when receiving emails from unknown senders, or emails that request sensitive company or personal information.',
        policy: 'Email and Communication Policy',
        img: p19
    },
    {
        id: 20,
        text: 'Users must ensure that contents within Company ABC electronic communications contain professional language.',
        policy: 'Email and Communication Policy',
        img: p20
    },
    {
        id: 21,
        text: 'When creating and sharing content on social media platforms, users must disclose that they are a Company ABC employee or work for Company ABC, especially if they are creating content relating to Company ABC\'s industry.',
        policy: 'Social Media Policy',
        img: p21
    }
];

const NUM_POLICIES = cards.length;

const PolicyGame: React.FC<GameProps> = (
    {
        setInstructions,
        isLeader,
        leaderName,
        currQuestionIndex,
        gameScore,
        updateVoteMessage,
        onSubmit,
        onQuestionIndexUpdate,
        onComplete,
        isInstructionPopupOpen,
        onLeaderAction,
    }) => {

    const [attemptsUsed, setAttemptsUsed] = useState(0);
    const [submitPolicy, setSubmitPolicy] = useState('');
    const [ripple, setRipple] = useState('');
    const [beingDragged, setBeingDragged] = useState(false);


    useEffect(() => {
        setInstructions({
            title: 'Puzzling Policies Instructions',
            subtitle: 'Welcome to the room! Here are the instructions you need to follow:',
            content: [
            "1. Read each policy card carefully.",
            "2. Drag and drop each card into the correct policy bucket.",
            "3. Correct matches increase your score, while incorrect matches decrease it.",
            "4. The game ends when all cards are sorted or time runs out.",
            "5. Alert the facilitator if you need assistance.",
            "",
            "The team leader controls what happens on-screen.",
            "Other players can see what the team leader does.",
            "",
            "Scoring: 1st try: 4 points, 2nd try: 2 points, 3rd try: 1 point",
        ]});

    }, [setInstructions]);

    useEffect(() => {

        if (currQuestionIndex === NUM_POLICIES) {
            onComplete();
        }

    }, [currQuestionIndex]);

    useEffect(() => {
        const preloadNextImage = () => {
            const nextImg = new Image();
            if (cards[currQuestionIndex + 1]) {
                nextImg.src = cards[currQuestionIndex + 1].img;
            }
        };

        preloadNextImage();
    }, [currQuestionIndex]);


    useEffect(() => {
        const onQuestionChange = (data: { isCorrect: boolean; }) => {
            if (data.isCorrect) {
                setAttemptsUsed(0);
                onQuestionIndexUpdate(currQuestionIndex + 1);
                setRipple("Correct");
            } else {
                setAttemptsUsed((prev) => prev + 1);
                setRipple("Incorrect");
            }

            setTimeout(() => {
                setRipple('');
            }, 1000);

        };

        eventEmitter.on('questionChange', onQuestionChange);


        return () => {
            eventEmitter.off('questionChange', onQuestionChange);
        };
}, [currQuestionIndex, onQuestionIndexUpdate]);


    useEffect(() => {
        function leaderActionListener(action: string) {
            if (action.startsWith("startHover")) {
                const policy = action.replace("startHover", "").trim();
                eventEmitter.emit('startHover', policy);
            } else if (action.startsWith("endHover")) {
                const policy = action.replace("endHover", "").trim();
                eventEmitter.emit('endHover', policy);
            } else if (action === "startDrag") {
                setBeingDragged(true);
            } else if (action === "endDrag") {
                setBeingDragged(false);
                eventEmitter.emit('endHover', "all");
            } else {
                setSubmitPolicy(action);
                eventEmitter.emit('endHover', "all");
            }
        }

        eventEmitter.on('leaderAction', leaderActionListener);

        return () => {
            eventEmitter.off('leaderAction', leaderActionListener);
        };

    }, []);

    // handle dropping a card into a bucket. score starts at 4, goes to 2 then 1 then 0 for each incorrect guess on that card
    // then, emit message containing core game element changes
    async function handleDrop(policy: string) {

        console.log("handling Drop");

        if (!isLeader) return;
        const message = `${leaderName} has dropped this card into ${policy}.`
        updateVoteMessage(message);

        onLeaderAction(policy);

        const card = cards[currQuestionIndex];
        let isCorrect = false;
        let scoreIncrement = 0;
        if (card.policy === policy) {
            scoreIncrement = 4;
            isCorrect = true;

            if (attemptsUsed === 1) {
                scoreIncrement = 2;
            } else if (attemptsUsed === 2) {
                scoreIncrement = 1;
            } else if (attemptsUsed >= 3) {
                scoreIncrement = 0;
            }

        }

        onSubmit(isCorrect, scoreIncrement);

    }

    const onPolicyLeaderAction = useCallback(
        (action: string) => {
            if (action.startsWith("startHover")) {
                throttledLeaderAction(action);
            } else {
                onLeaderAction(action);
            }
        },
        [onLeaderAction]
    );

    const throttledLeaderAction = useCallback(
        throttle((action: string) => {
            onLeaderAction(action);
        }, 250), // Adjust the delay as needed
        [onLeaderAction]
    );

    // Clean up throttled function on unmount
    useEffect(() => {
        return () => {
            throttledLeaderAction.cancel();
        };
    }, [throttledLeaderAction]);



    // TODO: REFACTOR THE ON SUBMIT COLOUR RIPPLE TO POLICYGAME, JUST TELL BUCKET TO CHANGE COLOUR THROUGH PROP VALUE
    return (
        <ThemeProvider theme={theme}>
            {musicSrcList && <MusicPlayer musicSrcList={musicSrcList} startPlay={!isInstructionPopupOpen} />}
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <Container maxWidth={false} sx={{flex: '1 0 auto'}}>
                    <Grid container spacing={2} paddingTop={'4vh'}>
                        <Grid item xs={8} paddingBottom={'22px'}>
                            <Paper elevation={2}>
                                <Box p={1} display="flex" flexDirection="column" alignItems="center" sx={{ height: '100%' }}>
                                    <Box width="100%">
                                        <LinearProgressWithLabel color='generic' variant="determinate"
                                                                 value={100 * (1 - ((NUM_POLICIES - currQuestionIndex) / NUM_POLICIES))}/>
                                    </Box>
                                    <Box width="100%" textAlign="left" mt={1}>
                                        <Typography variant="h6"
                                                    data-cy="game-score">Score: {gameScore}</Typography>
                                    </Box>
                                    {cards[currQuestionIndex] && (
                                        <Grid item xs={6} key={cards[currQuestionIndex].id}>
                                            <CardComponent id={cards[currQuestionIndex].id}
                                                           text={cards[currQuestionIndex].text}
                                                           img={cards[currQuestionIndex].img}
                                                           beingDragged={beingDragged}
                                                           isLeader={isLeader}
                                                           onLeaderAction={onLeaderAction}
                                                           data-cy="policy-card"/>
                                        </Grid>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={2}>
                                <Box p={1}>
                                    <Box mt={1}>
                                        {['Cloud Services Policy', 'Email and Communication Policy', 'Acceptable Use Policy', 'Information Classification Handling', 'Physical Security Policy', 'Social Media Policy'].map((policy) => (
                                            <Bucket
                                                key={policy}
                                                policy={policy}
                                                label={letterMap[policy]}
                                                onDrop={handleDrop}
                                                submitPolicy={submitPolicy}
                                                ripple={ripple}
                                                onLeaderAction={onPolicyLeaderAction}
                                                data-cy={`bucket-${policy.replace(/\s+/g, '-').toLowerCase()}`}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default PolicyGame;
