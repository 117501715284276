import React, {useState, useEffect, ChangeEvent} from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Alert,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogContent,
    DialogActions
} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from '../../styling/palette';
import scenario1 from './img/scenario1.png';
import scenario2 from './img/scenario2.png';
import scenario3 from './img/scenario3.png';
import scenario4 from './img/scenario4.png';
import scenario5 from './img/scenario5.png';
import scenario6 from './img/scenario6.png';
import scenario7 from './img/scenario7.png';
import scenario8 from './img/scenario8.png';
import scenario9 from './img/scenario9.png';
import scenario10 from './img/scenario10.png';
import {GameProps} from "../types";
import eventEmitter from "../../contexts/EventEmitter";
import MusicPlayer from "../../components/MusicPlayer";

// playing in reverse order compared to policy
const policyMusic = require('../../assets/Policy.mp3');
const policyMusic2 =  require('../../assets/Policy2.mp3');
const musicSrcList: string[] =[policyMusic2, policyMusic];

const images = [scenario1, scenario2, scenario3, scenario4, scenario5, scenario6, scenario7, scenario8, scenario9, scenario10];

const questions: string[] = ["On your way to the office, you meet a coworker in the elevator. They engage in a conversation with you regarding a new confidential business opportunity. How do you respond?",
    "You arrive at the front door of the office and realize that you have forgotten your access card. How do you proceed?",
    "On your way to a meeting, you see someone in the office that you have never seen before. They look out of place, and you see them taking confidential printouts from the printer. What should you do?",
    "It's the end of your workday and you are about to go home. You lock your laptop in your desk, but you notice your coworker who has already gone home has left their laptop unsecured on their desk. What should you do?",
    "On your way out of the building you see that the office door is propped open. There is nobody else in the vicinity. What should you do?",
    "You receive the following email, and you are not sure if it is legitimate or if it is a phishing message. Determine which aspect of this message tells you it is a phishing message:",
    "You receive the following email, and you are not sure if it is legitimate or if it is a phishing message. Determine if this is a phishing or legitimate message:",
    "You receive the following email, and you are not sure if it is legitimate or if it is a phishing message. Determine the best reason why this is a phishing message:",
    "Jojo is taking a flight after her workday and arrived at the airport earlier to check-in. She can work remotely and decides to use Wi-Fi to connect to her work network. Which network appears to be the safest to connect to?",
    "Eve is travelling with her family on holidays to a restricted location. Which of the following should she do to protect Company ABC's data during her travels?"
];

const whys: string[][] = [
    ["Company ABC's acceptable use policy states that users must not discuss or share confidential company information outside of Company ABC offices and/or with Company ABC team(s) that are not authorized to access such information.", "Offices generally have more secure environments compared to public or home settings. Conversations in the office are less likely to be overheard by unauthorized individuals."],
    ["Company ABC's Physical Security Policy states that each person must only use their own access card to enter controlled doors within Company ABC offices. The sharing of access cards and tailgating is prohibited.", "Access cards help to ensure that only authorized individuals access areas within the office that are appropriate for that individual. This ensure that the Company ABC office remains a safe, and trusted environment."],
    ["Unauthorized individuals within the Company ABC office pose a risk of encountering confidential information which they could use for malicious purposes, or personal gain."],
    ["Locking your laptop prevents unauthorized individuals from using your laptop and potentially accessing sensitive information, installing malicious software, or making unauthorized changes.", "Even trusted colleagues could accidentally access or alter important files if your laptop is left unsecured."],
    ["An open door can compromise the security of the Company ABC offices and allow unauthorized individuals to enter the office."],
    ["Malicious individuals will craft email addresses and URLs to closely resemble the legitimate site/email they are spoofing. It is important to always verify that emails are coming from a known and expected address."],
    ["The from email address is 'gitbub' rather than 'github'. As with the previous example, changes to legitimate addresses may be hardly noticeable, but can result in a successful compromise."],
    ["The URL that the 'Accept Invite' button take you to is a malicious address that closely resembles a legitimate website. Attackers use buttons within emails to disguise malicious URLs. Try to always copy and paste a link from an email, rather than clicking on a hyperlink as it may take you to a different page than you are expecting."],
    ["When connecting to Wi-Fi in a public location it is important to connect to only known, secured Wi-Fi networks. Wi-Fi networks can be setup to resemble legitimate networks but are used maliciously to intercept your private information."],
    ["When travelling, care must be taken to protect company information. If a personal phone is lost and has access to Company ABC accounts, the information could be obtained by malicious individuals."]
]

const options: string[][] = [
    ["A. Engage in the conversation.", "B. Mention that you should wait until you are in the office to discuss.", "C. Ignore your coworker.", "D. Report your coworker to the CFO and file an incident report."],
    ["A. Wait for a colleague to come to the door, and ask them to let you in.", "B. Wait for a colleague to open the door, and follow them in.", "C. Obtain a one-day temporary card from reception.", "D. Return home and call in sick."],
    ["A. Continue to your meeting, there must be an employee sponsor nearby.", "B. Ask whether the person is a visitor and if they need help finding the Company ABC employee they have an appointment with.", "C. Don't interact with them and report them to the facilities team."],
    ["A. Leave it as-is, your coworker must have had a reason to leave it out.", "B. Call your coworker to see if they were coming back to work that night. If not, lock up the laptop in your desk for them.", "C. Put the laptop in your coworker's unlocked desk cabinet.", "D. Cover the laptop with a file folder so it is hidden from public view."],
    ["A. Close the door so it is only slightly open and less obvious.", "B. Contact facilities to report the open door and ask if it should be closed.", "C. Leave the door open, it must be open for a reason.", "D. Wait at the door until someone comes by to ask about the open door."],
    ["A. The 'From' address", "B. The 'To' address", "C. The email subject", "D. The embedded link"],
    ["A. Phishing", "B. Legitimate"],
    ["A. Company ABC does not use ClickUp.", "B. Oscar.trent@Company ABC.com is not clickable.", "C. The 'Accept Invite' is invalid.", "D. Company ABC does not capitalize names in email addresses."],
    ["A. FastWifiConnectToMe (Not Secured)", "B. Bob's iPhone (Secured)", "C. @yvrairport (Secured)", "D. YvR@irp0rt (Not Secured)"],
    ["A. Bring only hard copy confidential files.", "B. Share her detailed travel itinerary with her manager.", "C. Log out of all Company ABC accounts on her personal devices.", "D. Reset her Company ABC password multiple times throughout her travels."]
];

const answers: string[] = ["B", "C", "B", "B", "B", "A", "A", "C", "C", "C"];

// component representing the Lockdown Legends game.

const LockdownLegends: React.FC<GameProps> = (
    {
        setInstructions,
        setTimeLimit,
        isLeader,
        leaderName,
        currQuestionIndex,
        isInstructionPopupOpen,
        gameScore,
        updateVoteMessage,
        onSubmit,
        onQuestionIndexUpdate,
        onLeaderAction,
        onComplete,
    }) => {
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [questionStatus, setQuestionStatus] = useState("neutral");
    const [attemptsUsed, setAttemptsUsed] = useState(0);
    const [isZoomedImageOpen, setIsZoomedImageOpen] = useState(false);
    const [explanationOpen, setExplanationOpen] = useState(false);

    useEffect(() => {
        setInstructions({
            title: 'Lockdown Legends Instructions',
            subtitle: 'Welcome to the room! Here are the instructions you need to follow:',
            content: [
            "1. During a typical day in the office, you will encounter scenarios related to physical security.",
            "2. When presented with a scenario, select the most appropriate way to respond.",
            "3. The more attempts you use, the less points you will get for handling the scenario",
            "4. The game ends when all scenarios are correctly handled, or time runs out.",
            "5. Alert the facilitator if you need assistance.",
            "",
            "The team leader controls what happens on-screen.",
            "Team members can see what the team leader does.",
            "",
            "Scoring: 1st try: 4 points, 2nd try: 2 points, 3rd try: 1 point, 4th try: 0 points",
        ]});

        setTimeLimit(8 * 60);
    }, []);

    useEffect(() => {
        const preloadNextImage = () => {
            if (images[currQuestionIndex + 1]) {
                const nextImg = new Image();
                nextImg.src = images[currQuestionIndex + 1];
            }
        };

        preloadNextImage();
    }, [currQuestionIndex]);

    useEffect(() => {
        const onQuestionChange = (data: { isCorrect: boolean }) => {

            setQuestionStatus("neutral");
            setTimeout(() => {
            if (data.isCorrect) {
                console.log("within LL, CQI is: ", currQuestionIndex, " and marking currQ as completed");
                setQuestionStatus("correct");
            } else {
                console.log("Got the question wrong. Current attempts:", attemptsUsed);
                setAttemptsUsed((prev) => prev + 1);
                setQuestionStatus("incorrect");
            }
        }, 100);
        };

        eventEmitter.on('questionChange', onQuestionChange);


        return () => {
            eventEmitter.off('questionChange', onQuestionChange);
        };

    }, [currQuestionIndex, onQuestionIndexUpdate, attemptsUsed]);


    useEffect(() => {
        function leaderActionListener(action: string) {
            console.log("leaderAction is: ", action);
            if (action === "nextQuestion") {
                onQuestionIndexUpdate(currQuestionIndex + 1);
                setSelectedAnswer("");
                setQuestionStatus("neutral");
            } else if (action === "close") {
                setExplanationOpen(false);
            } else if (action.length === 1) {
                setSelectedAnswer(action);
            }
        }



        eventEmitter.on('leaderAction', leaderActionListener);

        return () => {
            eventEmitter.off('leaderAction', leaderActionListener);
        };

    }, [currQuestionIndex]);


    useEffect(() => {

        if (currQuestionIndex === questions.length) {
            onComplete();
        }

    }, [currQuestionIndex]);

    const handlePopup = () => {
        setIsZoomedImageOpen(!isZoomedImageOpen);
    }

    // emit event for changing answer selection for current question

    const handleChangeAnswer = (event: ChangeEvent<HTMLInputElement>) => {
        if (isLeader && (questionStatus !== 'correct')) {
            onLeaderAction((event.target as HTMLInputElement).value);
        }
    }

    function handleGoToNextQuestion() {
        onLeaderAction("nextQuestion");
    }

    const handleExplanationClose = () => {
        setExplanationOpen(false);

        if (isLeader) {
            onLeaderAction('close');
        }
    };

    // when an answer is submitted:
    // - increment attempts used on that question by 1
    // - emit event with attempts used changes
    // - if answer is correct, emit score increment events according to scoring scheme
    // - emit event to change question status based on whether submission is correct or not
    const handleSubmitAnswer = async (selectedAnswer: string) => {
        if (!isLeader || selectedAnswer === "") return;

        updateVoteMessage(`${leaderName} has selected ${selectedAnswer.at(0)}.`);

        let isCorrect = false;
        let scoreIncrement = 0;

        console.log("selected answer is: ", selectedAnswer, " and correct answer is: ", answers[currQuestionIndex]);

        if (answers[currQuestionIndex] === selectedAnswer) {
            scoreIncrement = 4;
            isCorrect = true;

            if (attemptsUsed === 1) {
                scoreIncrement = 2;
            } else if (attemptsUsed === 2) {
                scoreIncrement = 1;
            } else if (attemptsUsed >= 3) {
                scoreIncrement = 0;
            }
        }

        console.log("providing baseRoom with scoreIncrement of: ", scoreIncrement, " and curr gameScore is: ", gameScore);
        onSubmit(isCorrect, scoreIncrement);
    }


    const displayedQuestionIndex = Math.min(currQuestionIndex, questions.length - 1);
    const isZoomable = [5, 6, 7].includes(displayedQuestionIndex);

    return (
        <ThemeProvider theme={theme}>
            {musicSrcList && <MusicPlayer musicSrcList={musicSrcList} startPlay={!isInstructionPopupOpen} />}
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <Container maxWidth={false} sx={{maxHeight: 'none', paddingTop: '10px'}}>
                    <Box display='flex' flexDirection={"column"} alignItems={"center"} gap={5}>
                        <Box display='flex' justifyContent={'center'} gap={10}>
                            <Typography variant="h5">Scenario {displayedQuestionIndex + 1} / 10</Typography>
                            <Typography variant="h5">Score: {gameScore}</Typography>
                        </Box>
                        <Grid display='flex' container alignItems="flex-start" gap={5} justifyContent='center'>
                            {/* 'question area' - contains question and radio button group for options */}
                            <Grid item xs={4} display='flex' flexDirection="column" gap={2}>
                                <Typography overflow={"auto"}>{questions[displayedQuestionIndex]}</Typography>
                                <RadioGroup name="answers-group" value={selectedAnswer} onChange={handleChangeAnswer}
                                            sx={{gap: 2}}>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQuestionIndex)!.length >= 1 ? 'visible' : 'hidden'}}
                                        value="A" control={<Radio/>} label={options.at(displayedQuestionIndex)!.at(0)}/>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQuestionIndex)!.length >= 2 ? 'visible' : 'hidden'}}
                                        value="B" control={<Radio/>} label={options.at(displayedQuestionIndex)!.at(1)}/>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQuestionIndex)!.length >= 3 ? 'visible' : 'hidden'}}
                                        value="C" control={<Radio/>} label={options.at(displayedQuestionIndex)!.at(2)}/>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQuestionIndex)!.length >= 4 ? 'visible' : 'hidden'}}
                                        value="D" control={<Radio/>} label={options.at(displayedQuestionIndex)?.at(3)}/>
                                </RadioGroup>
                                <Button variant="contained" color="generic"
                                        onClick={questionStatus === "correct" ? () => handleGoToNextQuestion() : () => handleSubmitAnswer(selectedAnswer)}>
                                    {questionStatus === "correct" ? (displayedQuestionIndex === 9 ? "Finish!" : "Next Question") : "Submit"}
                                </Button>
                            </Grid>
                            <Box display='flex' alignItems="center" justifyContent='center' width='450px'
                                 height='350px'>
                                <figure style={{textAlign: 'center'}}>
                                    <img src={images[displayedQuestionIndex]} alt="Suspects"
                                         style={{
                                             maxWidth: '450px',
                                             maxHeight: '350px',
                                             objectFit: 'contain',
                                             cursor : isZoomable ? 'pointer' : 'default'}}
                                         onClick={isZoomable ? handlePopup : undefined}/>
                                    {isZoomable && <figcaption>Click to zoom</figcaption>}
                                </figure>
                            </Box>
                        </Grid>
                        <Alert variant={"outlined"} severity={questionStatus === "correct" ? "success" : "error"}
                               style={{
                                   visibility: questionStatus !== "neutral" ? "visible" : "hidden",
                                   transition: "opacity 0.5s ease, transform 0.5s ease",
                                   transform: questionStatus !== "neutral" ? "translateY(0)" : "translateY(-10px)",
                        }}>
                            {questionStatus === "correct" ?
                                <div>
                                    <Typography style={{paddingBottom: 2}}>Correct!</Typography>
                                    {whys[displayedQuestionIndex].map((why) => (
                                        <Typography fontSize={'12px'}>{why}</Typography>
                                    ))}
                                </div>
                                : <Typography>Incorrect - please try again.</Typography>}
                        </Alert>
                    </Box>
                </Container>
                {isZoomedImageOpen && (
                    <Dialog
                        open={isZoomedImageOpen}
                        onClick={handlePopup}
                        maxWidth={false} // Allow Dialog to size according to content
                        onClose={handlePopup}
                        PaperProps={{
                            style: {
                                margin: '2px', // Remove default margins to maximize space
                            },
                        }}
                    >
                        <DialogContent
                            style={{
                                padding: 0, // Remove default padding
                            }}
                        >
                            <img
                                src={images[displayedQuestionIndex]}
                                alt="Suspects"
                                style={{
                                    display: 'block', // Ensure the image is treated as a block element
                                    width: '100%', // Scale the image to fill the DialogContent width
                                    height: 'auto', // Maintain aspect ratio
                                    minWidth: '65vw',
                                    minHeight: '65vh',
                                    maxWidth: '75vw', // Prevent image from exceeding viewport width
                                    maxHeight: '75vh', // Prevent image from exceeding viewport height
                                }}
                            />
                        </DialogContent>
                    </Dialog>
                )}
                <Dialog open={explanationOpen} onClose={handleExplanationClose}>
                    <DialogContent>
                        {questionStatus !== 'neutral' && (
                            <Typography variant="h6"
                                        sx={{color: questionStatus === 'correct' ? 'green' : 'red', mb: 2}}>
                                {questionStatus === 'correct' ? 'Correct!' : 'Incorrect!'}
                            </Typography>
                        )}
                        <Typography>{whys[displayedQuestionIndex]}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleExplanationClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </ThemeProvider>
    );
};

export default LockdownLegends;
