// Props taken by the question popup (whenever the user clicks on an object)
import React, {ChangeEvent} from "react";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "../../styling/palette";
import {
    Alert, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import eventEmitter from "../../contexts/EventEmitter";


interface Question {
    device: string;
    answers: string[];
    whys: string[];
}

interface QuestionPopupProps {
    question: number,
    open: boolean,
    isLeader: boolean,
    questionStatus: string,
    selectedAnswer: string,
    onClose: () => void,
    selectionChange: (selection: string) => void;
    onSubmit: (question: number, answer: string) => void,
}


const questions: Question[] = [
    {
        device: "Smart Thermostat",
        answers: [
            "A. Regularly update the smart thermostat's firmware and use a strong, unique password.",
            "B. Keep the smart thermostat on its factory settings to ensure it functions correctly.",
            "C. Connect the smart thermostat to a public Wi-Fi network for better performance.",
            "D. Use the default password provided by the manufacturer."
        ],
        whys: [
            "Firmware Updates: Regular updates often include patches for security vulnerabilities that could be exploited by hackers. Keeping the firmware up-to-date helps protect against potential cyber attacks.",
            "Strong Passwords: Using a strong, unique password reduces the risk of unauthorized access. Weak or reused passwords can be easily guessed or cracked, compromising your system's security."
        ]
    },
    {
        device: "Router",
        answers: [
            "A. Use the default SSID and password that come with the router.",
            "B. Use WPA3 encryption and a strong, unique password for your Wi-Fi network.",
            "C. Disable the router's firewall to improve connection speed.",
            "D. Keep the Wi-Fi network open and unencrypted for easier access."
        ],
        whys: [
            "WPA3 Encryption: WPA3 is the latest Wi-Fi security protocol, offering stronger encryption and better protection against attacks such as brute force attempts. It provides more robust security than its predecessors (WPA2).",
            "Strong, Unique Password: A strong, unique password is harder for attackers to guess or crack, reducing the likelihood of unauthorized access."
        ]
    },
    {
        device: "Computer",
        answers: [
            "A. Install only trusted software from reputable sources and disable automatic updates for stability.",
            "B. Use the same password across multiple accounts for convenience.",
            "C. Ensure your day-to-day user account has administrative access.",
            "D. Keep your operating system and software up to date with the latest security patches."
        ],
        whys: [
            "Protection Against Vulnerabilities: Security patches fix known vulnerabilities in the operating system and software that could be exploited by hackers. Keeping your system updated helps protect against these threats.",
            "Defense Against Malware: Many updates include improvements to security features that help protect against viruses, spyware, ransomware, and other malicious software."
        ]
    },
    {
        device: "Printer",
        answers: [
            "A. Keep the printer's default settings for ease of use.",
            "B. Regularly update the printer's firmware to ensure the latest security patches are installed.",
            "C. Connect the printer to your home network without a password for quick access.",
            "D. Allow remote printing from any device to enhance convenience."
        ],
        whys: [
            "Protection Against Vulnerabilities: Firmware updates often address security vulnerabilities that could be exploited by hackers. Keeping your printer's firmware updated helps protect it from unauthorized access and attacks.",
            "Prevention of Data Breaches: Printers can store sensitive information such as print jobs and network credentials. Updated firmware helps safeguard this data against breaches."
        ]
    },
    {
        device: "Google Home",
        answers: [
            "A. Use Google Home to take notes on the work you are performing.",
            "B. Always keep the microphone on for quick responses.",
            "C. Turn off the microphone when discussing sensitive/confidential information.",
            "D. Connect your Google Home device to an unencrypted Wi-Fi network for better compatibility."
        ],
        whys: [
            "Prevent Unauthorized Listening: Even though Google Home devices are designed to activate only when they detect the wake word, there is a possibility of accidental activation. Turning off the microphone ensures that your conversations remain private and are not inadvertently recorded.",
            "Data Security: Sensitive information discussed in your home could be potentially captured and transmitted. Disabling the microphone minimizes the risk of this data being stored or accessed by unauthorized parties."
        ]
    },
    {
        device: "Smart Garbage Can",
        answers: [
            "A. Install the latest firmware updates and change the default password to something more secure, like 'Tr@shC4n$ecure'.",
            "B. Only connect it to an open WiFi network, keeping it separate from your main devices.",
            "C. Disable the WiFi feature entirely and just use it as a regular garbage can, because who really needs a smart garbage can?",
            "D. Wrap the garbage can in aluminum foil to block all WiFi signals and prevent hacking."
        ],
        whys: [
            "Improved Encryption: Updates may include enhanced encryption methods, making it harder for attackers to intercept or manipulate data transmitted by the device.",
            "Strong Passwords: Using a strong, unique password reduces the risk of unauthorized access. Weak or reused passwords can be easily guessed or cracked, compromising your system's security. Default passwords are often the same on all of the devices produced by the manufacturer, making it easy for an attacker to access your device."
        ]
    },
    {
        device: "Nanny Cam",
        answers: [
            "A. Disable the nanny cam's WiFi feature and use an Ethernet cable instead.",
            "B. Set up a separate, secured WiFi network that's dedicated to smart home devices.",
            "C. Never update the nanny cam, as it was secure when you bought it.",
            "D. Back up your nanny cam to the public cloud."
        ],
        whys: [
            "Limit Exposure: By segregating smart home devices from your primary network, you reduce the risk of them being a potential entry point for attackers to access more sensitive devices like your personal computers or smartphones.",
            "Contain Breaches: If a smart device on the dedicated network is compromised, the attacker's access is limited to that network, preventing the breach from spreading to your primary network."
        ]
    }
];

// The popup that displays when the player clicks on an object. Shows the question, option radio buttons, and submit / cancel buttons.
const QuestionPopup: React.FC<QuestionPopupProps> = (
    {
        question,
        open,
        isLeader,
        onClose,
        questionStatus,
        selectedAnswer,
        selectionChange,
        onSubmit
    }) => {


    // change the selected answer. should only have effect when leader
    const handleChangeAnswer = (event: ChangeEvent<HTMLInputElement>) => {
        if (isLeader) {
            const selectedValue = (event.target as HTMLInputElement).value;
            selectionChange(selectedValue); // Call the parent's callback with the selected answer string
        }
    }

    // submit the answer. should only have effect when leader
    const handleSubmitAnswer = (question: number, answer: string) => {
        if (isLeader) {
            onSubmit(question, answer);
        }
    }

    // when the popup is closed, clear currently selected choice. should only have effect when leader
    const handleOnClose = () => {
        onClose();
    }


    if (!open) return null;

    const currentQuestion = questions[question];

    const currentAnswers = currentQuestion?.answers || ["Option A not available", "Option B not available", "Option C not available", "Option D not available"];
    const currentWhys = currentQuestion?.whys || ["Explanation not available"];


    return (
        <ThemeProvider theme={theme}>
            <Dialog open={open} onClose={onClose}>
                {/* Display the device name */}
                <DialogTitle>{currentQuestion?.device || "Unknown Device"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <Typography>Select the best practice associated with this device.</Typography>
                            <RadioGroup name="answers-group" value={selectedAnswer} onChange={handleChangeAnswer}>
                                <FormControlLabel
                                    value="A"
                                    control={<Radio sx={{cursor: isLeader ? 'pointer' : 'default'}} />}
                                    label={<Typography>{currentAnswers[0]}</Typography>}
                                    data-cy="answer-A"
                                />
                                <FormControlLabel
                                    value="B"
                                    control={<Radio sx={{cursor: isLeader ? 'pointer' : 'default'}} />}
                                    label={<Typography>{currentAnswers[1]}</Typography>}
                                    data-cy="answer-B"
                                />
                                <FormControlLabel
                                    value="C"
                                    control={<Radio sx={{cursor: isLeader ? 'pointer' : 'default'}} />}
                                    label={<Typography>{currentAnswers[2]}</Typography>}
                                    data-cy="answer-C"
                                />
                                <FormControlLabel
                                    value="D"
                                    control={<Radio sx={{cursor: isLeader ? 'pointer' : 'default'}} />}
                                    label={<Typography>{currentAnswers[3]}</Typography>}
                                    data-cy="answer-D"
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Alert variant={"outlined"} severity={"success"} style={{ display: questionStatus === "correct" ? "flex" : "none" }}>
                        <Typography sx={{ paddingBottom: 2 }}>Correct! Here's why...</Typography>
                        {currentWhys.map((why, index) => (
                            <Typography key={index}>{why}</Typography>
                        ))}
                    </Alert>
                    <Alert variant={"outlined"} severity={"error"} style={{ display: questionStatus === "incorrect" ? "flex" : "none" }}>
                        <Typography>Incorrect, please try again.</Typography>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose} variant={questionStatus === "correct" ? "contained" : "text"}
                            color="generic" disabled={!isLeader} data-cy="continue-button">
                        {questionStatus === "correct" ? "Continue" : "Cancel"}
                    </Button>
                    <Button onClick={() => handleSubmitAnswer(question, selectedAnswer)} color="generic"
                            style={{ display: questionStatus === "correct" ? "none" : "flex" }}
                            disabled={!isLeader || questionStatus === "correct"} data-cy="submit-button">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default QuestionPopup;