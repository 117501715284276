import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, useTheme, useMediaQuery
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {InstructionsType} from "../lobbies/types";

interface InstructionPopupProps {
  open: boolean;
  onClose: () => void;
  instructions: InstructionsType;
  boldText?: string;
}

/* 
Popup that appears for everyone at the start of the game showing the instructions and who the team leader is. This popup is viewable at any time during the game 
by clicking the instructions button in the appbar. Participants need to click the Got it! button for it to disappear. Mainly just styling here.
*/

const InstructionPopup: React.FC<InstructionPopupProps> = ({ open, onClose, instructions, boldText}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { title, subtitle, content } = instructions;

  return (
    <ThemeProvider theme={theme}>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.paper,
          borderRadius: '15px',
          padding: '20px'
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h5" component="div" style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{ marginBottom: '10px' }}>
          {subtitle}
        </Typography>
        <Typography variant="body2" paragraph style={{ lineHeight: '1.6' }} color="red">
          <React.Fragment>
            <b>{boldText}</b><br/>
          </React.Fragment>
        </Typography>
        <Typography variant="body2" paragraph style={{ lineHeight: '1.6' }}>
          {content.map((instruction, index) => (
            <React.Fragment key={index}>
              {instruction}<br />
            </React.Fragment>
          ))}
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          variant="contained" color="generic"
          data-cy="close-button"
          style={{
            backgroundColor: theme.palette.generic.main,
            color: theme.palette.generic.contrastText,
            padding: isSmallScreen ? '10px 20px' : '10px 40px',
            borderRadius: '25px',
            fontWeight: 'normal'
          }}
        >
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
    </ThemeProvider>
  );
};

export default InstructionPopup;
