import React, {useRef} from "react";
import {useDrag} from "react-dnd";
import {styled, Box, CardContent, Typography, Card} from "@mui/material";
import {CardComponentProps, ItemTypes} from "../../types";


// Component and styles representing a policy 'card'.
// uses react-dnd library to enable drag and drop features.
const StyledCard = styled(Card)(({theme}) => ({
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
    cursor: 'grab',
    backgroundColor: 'white',
    color: '#fff',
    fontSize: '0.8rem',
    width: '100%',
    border: '1px solid #ccc',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    height: '460px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    '&:active': {
        cursor: 'grabbing',
    },
}));
const CardComponent: React.FC<CardComponentProps> = ({id, text, img, isLeader, beingDragged, onLeaderAction}) => {
    const ref = useRef<HTMLDivElement>(null);


    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.CARD,
        // emits events for being dragged and dropped so all players on team can see it
        item: () => {
            onLeaderAction("startDrag");
            return {id};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => {
            onLeaderAction("endDrag");
        },
    });

    drag(ref);

    return (
        <div
            ref={ref}
            data-cy={`policy-card-${id}`}
            style={{
                opacity: beingDragged ? 0.5 : 1,
                pointerEvents: isLeader ? "auto" : "none",
                cursor: isDragging ? 'grabbing' : 'grab',
                width: '100%', // Ensure entire card is draggable
            }}>
            <StyledCard>
                <CardContent>
                    <Box display={"flex"} flexDirection={"column"} gap={2.5} alignItems={"center"}>
                        <Box
                            component="img"
                            src={img}
                            sx={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: 200,
                                objectFit: 'contain',
                                marginTop: 2.5,
                            }}
                        />
                        <Typography variant="body2" style={{
                            fontSize: 'min(0.9rem, 1.2vw)',
                            color: 'black',
                            marginBottom: 2
                        }}>
                            {id + ". " + text}
                        </Typography>
                    </Box>
                </CardContent>
            </StyledCard>
        </div>
    );
};

export default CardComponent;