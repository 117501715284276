import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, AppBar, Toolbar, Button, Snackbar, Alert } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useTeam } from '../../contexts/TeamContext';
import { useSocket } from '../../contexts/SocketContext';
import { theme } from '../../styling/palette'; // Ensure the path to palette.js is correct
import image from './assets/FinaleGameIntro.png'
import Logos from '../../components/Logos';
import MusicPlayer from "../../components/MusicPlayer";

const finaleIntroMusic =  require('../../assets/FinaleLobby.mp3');
const musicSrcList: string[] =[finaleIntroMusic];

const FinaleGameIntro = () => {
  const { teamName } = useTeam();
  const { socket } = useSocket();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (socket) {
      socket.on('broadcastMessage', (data) => {
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
      });

      return () => {
        socket.off('broadcastMessage');
      };
    }
  }, [socket]);

  const handleStartGame = () => {
    if (socket) {
      socket.emit('startFinaleGame');
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {musicSrcList && <MusicPlayer musicSrcList={musicSrcList} startPlay={true} />}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar style={{ top: 0 }} position="sticky" color="generic">
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
              {teamName} Who Wants To Be A Security Expert?
            </Typography>
          </Toolbar>
        </AppBar>

        <Container maxWidth="md" sx={{ flex: '1 0 auto', textAlign: 'center' }}>
          <Box
            component="img"
            sx={{
              width: '80%',
              height: 'auto',
              maxWidth: '60%',
              maxHeight: '60%',
              margin: '0 auto',
            }}
            alt="Finale Game"
            src={image}
          />
          <Box mt={4} sx={{ textAlign: 'left' }}>
            <Typography variant="h6" gutterBottom>
              Instructions
            </Typography>
            <Typography variant="body2" gutterBottom>
              <b>1. Question Rounds:</b> The game is divided into three rounds. Each round consists of a series of multiple-choice questions.
            </Typography>
            <Typography variant="body2" gutterBottom>
              <b>2. Scoring System:</b> Each player climbs the ladder of points.
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ marginLeft: '2%' }}>
              <b>i.</b> Players must answer each question correctly or else they are eliminated from the game.
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ marginLeft: '2%' }}>
              <b>ii.</b> Players lose any points they have earned if they do not reach one of the milestones.
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ marginLeft: '2%' }}>
              <b>iii.</b> Points earned during the finale game will be added to the points earned during the missions.
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ marginLeft: '2%' }}>
              <b>iv.</b> If there is a tie in points once the final scores are tallied, there will be a final showdown question to break the tie.
            </Typography>
          </Box>
          {teamName.toLowerCase().startsWith('facilitator') && (
            <Box display="flex" justifyContent="center" mt={4}>
              <Button variant="contained" color="generic" onClick={handleStartGame}>
                Start Game
              </Button>
            </Box>
          )}
        </Container>
        <Box sx={{ bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto' }}>
          <Logos />
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={null}
          onClose={(event, reason) => { if (reason !== 'clickaway') handleCloseSnackbar(); }}
        >
          <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default FinaleGameIntro;
