import React, { useEffect, useState } from 'react';
import {Container, Box, Typography, TextField, Button, Alert, LinearProgress} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../contexts/SocketContext';
import { useTeam } from '../contexts/TeamContext';
import { theme } from '../styling/palette';
import { ThemeProvider } from '@mui/material/styles';
import Background from './Background';

interface APIResponse {
  token: string;
  role: string;
}

const OnboardingPage: React.FC = () => {
  const navigate = useNavigate();
  const [teamNameInput, setTeamNameInput] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useSocket();
  const { setTeamName } = useTeam();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 2000); // Clear the error message after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true);

    const { success, error } = await login(teamNameInput, password);

    if (success) {
      setTeamName(teamNameInput);
      console.log("setting teamName in onboarding page");
      const role = localStorage.getItem('role');

      if (role === 'facilitator') {
        navigate('/facilitator-dashboard');
      } else {
        navigate('/email-entry');
      }
    } else if (error) {
      setErrorMessage(error);
      setLoading(false);
    }

  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
      }}>
        <Background />
        <Typography
            variant="h1"
            sx={{
              position: 'absolute',
              left: '0',            // Position towards bottom
              transform: 'rotate(90deg) translateX(430px) translateY(100px)',
              fontSize: '6rem',         // Large font size
              fontWeight: 'bold',       // Bold text
              color: 'white', // Contrast color from theme
              zIndex: 2,                // Ensure it's above the background
              textAlign: 'left',        // Align text to the left
            }}
        >
          Company ABC
        </Typography>
        <Box sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: { xs: '100%', md: '50%' },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          background: theme.palette.generic.main,
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            textAlign: 'center',
            mb: 4,
          }}>
            <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', mt: 2 }}>
              Welcome to the Company ABC Escape Room!
            </Typography>
          </Box>
          <Container maxWidth="sm" sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: 'white',
            padding: 4,
            borderRadius: 2,
            width: '80%',
            maxWidth: '400px',
          }}>
            <Typography variant="h5" align="center" gutterBottom>
              Enter your Team Login Information
            </Typography>
            {errorMessage && (
<Alert severity="error">{errorMessage}</Alert>
)}
            <form onSubmit={handleSubmit} data-cy="login-form">
              <TextField
                label="Team Name"
                fullWidth
                variant="outlined"
                margin="normal"
                value={teamNameInput}
                onChange={(e) => setTeamNameInput(e.target.value)}
                data-cy="team-name-input"
              />
              <TextField
                label="Password"
                fullWidth
                variant="outlined"
                margin="normal"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                data-cy="password-input"
              />
              <Button type="submit" variant="contained" color = "generic" fullWidth data-cy="login-button">
  Login
</Button>
              <LinearProgress style={{visibility: loading ? "visible" : "hidden"}} color='generic'/>
            </form>
          </Container>
        </Box>
        {/* <Box sx={{ mt: 'auto', width: '100%' }}>
          <Logos />
        </Box> */}
      </Box>
    </ThemeProvider>
  );
};

export default OnboardingPage;
