import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {io, Socket} from 'socket.io-client';
import {DefaultEventsMap} from 'socket.io/dist/typed-events';
import {useTeam} from "./TeamContext";
import axios from "axios";
import {red} from "@mui/material/colors";

/*
This keeps track of the socket, ensuring the same socket is present throguhout the appication and that that socket stays connected
it is initialized when the user joins. Connect socket is called. When the user disconnects, disconnect socket is called
*/

interface SocketContextType {
    socket: Socket<DefaultEventsMap, DefaultEventsMap> | null;
    connectSocket: () => void;
    disconnectSocket: () => void;
    login: (teamNameInput: string, password: string) => Promise<{ success: boolean; error?: string }>;
    submitUserName: (userName: string) => Promise<{ success: boolean; error?: string; currGame?: number }>;
    isLoggedIn: boolean;
}


const SocketContext = createContext<SocketContextType>({
    socket: null,
    connectSocket: () => {
    },
    disconnectSocket: () => {
    },
    login: async () => ({success: false, error: 'Not implemented'}),
    submitUserName: async () => ({success: false, error: 'Not implemented'}),
    isLoggedIn: false,
});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const socketRef = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const {setCurrGame, setTeamName, setUserName, setGameStarted} = useTeam();



    useEffect(() => {
        const path = window.location.pathname;

        // Check if we are on the onboarding or username entry pages
        if (path === '/' || path === '/email-entry') {
            console.log("Skipping state recovery on onboarding pages");
            return;
        }

        if (path == '/facilitator-dashboard') {
            redirectToOnboarding("on fac dashboard");
            return;
        }

        // Attempt state recovery for other pages
        const teamName = localStorage.getItem('teamName');
        const userName = localStorage.getItem('userName');
        const password = localStorage.getItem('password');
        const jwt = localStorage.getItem('jwt');

        // If any data is missing, navigate to the first page
        if (!teamName || !userName || !jwt || !password) {
            console.log("Insufficient data for state recovery. Redirecting to OnboardingPage.");
            window.location.href = '/';
            return;
        }

        // Attempt state recovery

        function redirectToOnboarding(reason: string) {
            console.log(`${reason}. Redirecting to Onboarding.`);
            window.location.href = '/';
        }
        async function recoverState() {
            try {

                if (!teamName || !userName || !password) {
                    redirectToOnboarding("Missing state information");
                    return;
                }

                // Attempt to log in
                const loginResult = await login(teamName, password);
                if (!loginResult.success) {
                    redirectToOnboarding("Login failed");
                    return;
                }

                setUserName(userName);

                // Attempt to submit username after successful login
                const {success, currGame} = await submitUserName(userName);
                if (!success || currGame === undefined || !socketRef.current) {
                    redirectToOnboarding("Failed to submit username");
                    return;
                }

                console.log("setting currGame to: ", currGame);
                setCurrGame(currGame);

                try {
                    await axios.put('/api/update-room', {
                        sid: socketRef.current.id,
                        room: currGame,
                        teamName: teamName
                    });
                    console.log("setting the room in player DB to: ", currGame);
                } catch (error) {
                    console.error("Failed to update room:", error);
                    redirectToOnboarding("Failed to update room");
                    return;
                }

                    console.log("setting game started value");
                    socketRef.current.emit('checkGameStatus', (response: { gameStarted: boolean; }) => {
                        if (response.gameStarted) {
                            setGameStarted(true);
                        }
                    });


                console.log("Session recovery successful!");
                setTeamName(teamName);
                setUserName(userName);
                socketRef.current.emit("randomSelect", {game: currGame, sid: socketRef.current.id});

            } catch (error) {
                console.error("Error during state recovery", error);
                redirectToOnboarding("An error occurred during state recovery");
            }
        }

        void recoverState();
    }, []);


    async function login(teamName: string, password: string): Promise<{ success: boolean; error?: string }> {
        if (teamName.length === 0 || password.length === 0) {
            return { success: false, error: 'Team name and password cannot be empty.' };
        }

        try {
            const response = await fetch('/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ teamName, password }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Received response.");
                localStorage.setItem('jwt', data.token);
                localStorage.setItem('teamName', teamName);
                localStorage.setItem('password', password);
                localStorage.setItem('role', data.role);

                await connectSocket();
                return { success: true };
            } else {
                let errorMessage = 'Unexpected error occurred.';
                if (response.status === 401) {
                    errorMessage = 'Wrong username or password.';
                } else if (response.status >= 400 && response.status < 500) {
                    errorMessage = 'Client error occurred.';
                } else if (response.status >= 500) {
                    errorMessage = 'Server error occurred.';
                }
                return { success: false, error: errorMessage };
            }
        } catch (error) {
            // Network error or server is down
            console.error("Network error:", error);
            return { success: false, error: 'Network error occurred.' };
        }
    }

    async function submitUserName(userName: string): Promise<{ success: boolean; error?: string; currGame?: number }> {
        if (!socketRef.current) {
            console.log("socket not established line 125");
            return {success: false, error: 'Socket not connected.'};

        }
        const socket = socketRef.current;
        return new Promise((resolve) => {
            console.log("emitting UserName to server");
            console.log("socket is: ", socket, " and socket id is:", socket.id);
            socket.emit("userName", {sid: socket.id, username: userName}, (response: { error?: string } | undefined) => {
                if (response?.error) {
                    console.log("error occurred during emitting userName to server");
                    resolve({success: false, error: response.error});
                } else {
                    localStorage.setItem('userName', userName);
                    socket.emit("getGameName", (currGame: number) => {
                        console.log("Got game: " + currGame);
                        setIsLoggedIn(true);
                        resolve({success: true, currGame});
                    });
                }
            });
        });
    }


    async function connectSocket(): Promise<void> {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('jwt');
            if (!token || socketRef.current) {
                console.log("Failed to create socket.")
                resolve(); // No need to connect or already connected
                return;
            }
            console.log("Creating new socket...");

            /*
            MAKE SURE TO CHANGE THESE WHEN SWITCHING FROM LOCAL AND DEV. THIS IS IMPORTANT. ONE USES LOCAL SOCKETS, ONE USES AZURE SOCKETS
            */
            // to local: comment out
            //
            const newSocket = io('https://vsecesc-deploy-socket.webpubsub.azure.com', {
                path: "/clients/socketio/hubs/Hub",
                auth: { token },
                withCredentials: true,
                transportOptions: {
                    polling: {
                        extraHeaders: {
                            'Access-Control-Allow-Origin': 'http://localhost:5000'
                        }
                    }
                }
            });

            // to local: uncomment
            // const newSocket = io('http://localhost', {
            //     auth: {token},
            //     withCredentials: true,
            //     transportOptions: {
            //         polling: {
            //             extraHeaders: {
            //                 'Access-Control-Allow-Origin': 'http://localhost:3000' //:3000
            //             }
            //         }
            //     }
            // });



            newSocket.on('connect', () => {
                console.log('Connected to server');
                console.log("setting socket in connectSocket");
                socketRef.current = newSocket;
                resolve();
            });

            // Handle connection error
            newSocket.on('connect_error', (error) => {
                console.error('Socket connection error:', error);
                reject(error);
            });

            newSocket.on('disconnect', () => {
                console.log('Disconnected from server');
                setIsLoggedIn(false);
                socketRef.current = null;
                alert(`Whoops! You have been disconnected. Please re-open the game and log in again with the same username.`);
            });


        });
    }

    const disconnectSocket = () => {
        if (socketRef.current) {
            socketRef.current.disconnect();
            socketRef.current = null;
        }
    };


    return (
        <SocketContext.Provider value={{socket: socketRef.current, connectSocket, disconnectSocket, login, submitUserName, isLoggedIn}}>
            {children}
        </SocketContext.Provider>
    );
};