import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";
import {LevelBoxProps} from "../types";

export const LevelBox = styled(Box)<LevelBoxProps>(({theme, current, correct, incorrect}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
    borderRadius: '25px',
    border: current ? '3px solid' : '1px solid',
    backgroundColor: correct ? '#97DBB3' : incorrect ? '#F09A9A' : 'white',
}));
