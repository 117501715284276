import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocket } from './SocketContext';
import {useTeam} from "./TeamContext";


/*
One of the main changes that was made was the facilitor is able to bring eveyone to the finale game. This component is a global event listener which all sockets
recieve when the facilitator wants to naviagte all the sockets to the finale game. It essentially just listens for a socket event and navigates everyone, not too 
much going on here.

*/

const GlobalEventListener: React.FC = () => {
    const navigate = useNavigate();
    const { socket } = useSocket();
    const {setIsLeader, setLeaderName, userName, setGameStarted, currGame} = useTeam();

    useEffect(() => {
        if (socket) {
            socket.on('gameStarted', () => {
                setGameStarted(true);
            })

            socket.on('randomSelect', (data: {picked: string}) => {
                console.log("received randomSelect from server, selected Leader is: ", data.picked, " and userName is: ", userName);
                if (data.picked === userName || currGame === 0) {
                    console.log("setting isLeader to be true in globalEventListener");
                    setIsLeader(true);
                    socket.emit("broadcastTeamList");
                } else {
                    setIsLeader(false);
                }
                setLeaderName(data.picked);
            });

            socket.on('navigateToFinale', () => {
                navigate('/finale-game');
            });

            socket.on('navigateToFinaleStart', () => {
                navigate('/finale-game-start');
            });
            

            return () => {
                socket.off('gameStarted');
                socket.off('randomSelect');
                socket.off('navigateToFinale');
                socket.off('navigateToFinaleStart');
            };
        }
    }, [socket, navigate, currGame, userName]);

    return null;
};

export default GlobalEventListener;
