import React, {ChangeEvent} from "react";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "../../../styling/palette";
import {Box} from "@mui/material";
import TextField from "@mui/material/TextField";
import {FormData} from "../InsiderInsights";

interface ddTableProps {
    isLeader: boolean;
    suspects: string[];
    weapons: string[];
    rooms: string[];
    formData: FormData;
    onLeaderAction: (leaderAction: string, data?: any) => void;
}


const DropdownTable: React.FC<ddTableProps> = ({isLeader, suspects, weapons, rooms, formData, onLeaderAction}) => {


    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldId: string
    ) => {
        const value = e.target.value;
        onLeaderAction('inputChange', {fieldId, value});
    };


// drop down table UI this was a pain to make...
    return (
        <ThemeProvider theme={theme}>
            <Box display="flex" justifyContent="center" mt={3} width="100%" sx={{paddingBottom: '80px'}}>
                <table style={{borderCollapse: 'collapse', width: '100%', tableLayout: 'fixed'}}>
                    <thead>
                    <tr>
                        <th
                            rowSpan={2}
                            style={{
                                border: '1px solid black',
                                padding: '10px',
                                fontSize: '14px',
                                backgroundColor: '#0099cc',
                                color: 'white',
                                textAlign: 'center',
                                width: '120px',
                                writingMode: 'vertical-rl',
                            }}
                        >
                            Suspects
                        </th>
                        <th
                            colSpan={rooms.length}
                            style={{
                                border: '1px solid black',
                                padding: '10px',
                                fontSize: '14px',
                                backgroundColor: '#33cccc',
                                color: 'white',
                                textAlign: 'center',
                                width: '220px',
                            }}
                        >
                            Room
                        </th>
                        <th
                            colSpan={weapons.length}
                            style={{
                                border: '1px solid black',
                                padding: '10px',
                                fontSize: '14px',
                                backgroundColor: '#809fff',
                                color: 'white',
                                textAlign: 'center',
                                width: '220px',
                            }}
                        >
                            Weapon
                        </th>
                    </tr>
                    <tr>
                        {rooms.map((room, index) => (
                            <th
                                key={index}
                                style={{
                                    border: '1px solid black',
                                    backgroundColor: '#33cccc',
                                    width: '40px', // Adjusted width for vertical text
                                    height: '160px', // Adjusted height to fit rotated text
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '150px',  // Width to accommodate the rotated text
                                        transform: 'translateX(-54px) rotate(-90deg)',
                                        transformOrigin: 'center',
                                        whiteSpace: 'nowrap',
                                        fontSize: '14px',
                                        color: 'white',
                                        textAlign: 'center',
                                    }}
                                >
                                    {room}
                                </div>
                            </th>
                        ))}
                        {weapons.map((weapon, index) => (
                            <th
                                key={index}
                                style={{
                                    border: '1px solid black',
                                    backgroundColor: '#809fff',
                                    width: '40px',
                                    height: '160px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '150px',
                                        transform: 'translateX(-54px) rotate(-90deg)',
                                        transformOrigin: 'center',
                                        whiteSpace: 'nowrap',
                                        fontSize: '14px',
                                        color: 'white',
                                        textAlign: 'center',
                                    }}
                                >
                                    {weapon}
                                </div>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {suspects.map((suspect, sIndex) => (
                        <tr key={sIndex}>
                            <td
                                style={{
                                    border: '1px solid black',
                                    padding: '10px',
                                    fontSize: '14px',
                                    backgroundColor: '#33cccc',
                                    color: 'white',
                                    textAlign: 'center',
                                }}
                            >
                                {suspect}
                            </td>
                            {rooms.map((_, rIndex) => {
                                const fieldId = `suspect_${sIndex}_room_${rIndex}`;
                                return (
                                    <td key={rIndex} style={{
                                        border: '1px solid black',
                                        padding: '0px',
                                        textAlign: 'center',
                                        width: '80px'
                                    }}>
                                        <TextField
                                            fullWidth
                                            inputProps={{
                                                maxLength: 1,
                                                style: {
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    padding: '5px'
                                                }
                                            }}
                                            variant="outlined"
                                            size="small"
                                            value={formData[fieldId] || ''}
                                            onChange={(e) => handleInputChange(e, fieldId)}
                                            style={{margin: 0}}
                                            disabled={!isLeader}  // Disable the input for non-leaders
                                        />
                                    </td>
                                );
                            })}
                            {weapons.map((_, wIndex) => {
                                const fieldId = `suspect_${sIndex}_weapon_${wIndex}`;
                                const isGrayOut = sIndex >= weapons.length; // This condition checks for the bottom-right quadrant

                                return (
                                    <td
                                        key={wIndex}
                                        style={{
                                            border: '1px solid black',
                                            padding: '0px',
                                            textAlign: 'center',
                                            width: '80px',
                                            backgroundColor: isGrayOut ? '#d3d3d3' : 'inherit', // Gray out the cell
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            inputProps={{
                                                maxLength: 1,
                                                style: {

                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    padding: '5px'
                                                }
                                            }}
                                            variant="outlined"
                                            size="small"
                                            value={formData[fieldId] || ''}
                                            onChange={(e) => handleInputChange(e, fieldId)}
                                            style={{margin: 0}}
                                            disabled={isGrayOut || !isLeader}  // Disable the input for gray out cells or non-leaders
                                        />
                                    </td>
                                );
                            })}
                        </tr>
                    ))}

                    {weapons.map((weapon, wIndex) => (
                        <tr key={wIndex}>
                            <td
                                style={{
                                    border: '1px solid black',
                                    padding: '10px',
                                    fontSize: '14px',
                                    backgroundColor: '#809fff',
                                    color: 'white',
                                    textAlign: 'center',
                                }}
                            >
                                {weapon}
                            </td>
                            {rooms.map((_, rIndex) => {
                                const fieldId = `weapon_${wIndex}_room_${rIndex}`;
                                const isGrayOut = wIndex >= suspects.length; // This condition checks for the bottom-right quadrant

                                return (
                                    <td
                                        key={rIndex}
                                        style={{
                                            border: '1px solid black',
                                            padding: '0px',
                                            textAlign: 'center',
                                            width: '80px',
                                            backgroundColor: isGrayOut ? '#d3d3d3' : 'inherit', // Gray out the cell
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            inputProps={{
                                                maxLength: 1,
                                                style: {
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    padding: '5px'
                                                }
                                            }}
                                            variant="outlined"
                                            size="small"
                                            value={formData[fieldId] || ''}
                                            onChange={(e) => handleInputChange(e, fieldId)}
                                            style={{margin: 0}}
                                            disabled={isGrayOut || !isLeader}  // Disable the input for gray out cells or non-leaders
                                        />
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Box>
        </ThemeProvider>
    );


}

export default DropdownTable;