import { ThemeProvider } from "@emotion/react";
import {theme} from "../../../styling/palette";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    Typography
} from "@mui/material";

// Make an accusation UI
const AccusationPopup: React.FC<{
    open: boolean,
    isLeader: boolean,
    onClose: () => void,
    onSubmit: (suspect: string, weapon: string, room: string) => void,
    onLeaderAction: (leaderAction: string, data?: any) => void;
    suspects: string[],
    weapons: string[],
    rooms: string[],
    selectedSuspect: string,
    selectedWeapon: string,
    selectedRoom: string
}> = ({open, isLeader, onClose, onSubmit, onLeaderAction, suspects, weapons, rooms, selectedSuspect, selectedWeapon, selectedRoom}) => {
    // broadcasts all of this stuff to the other team members. When an accusation is made

    const handleSubmit = () => {
        onSubmit(selectedSuspect, selectedWeapon, selectedRoom);
    };

    const handleSetSelectedSuspect = (e: string) => {
        if (isLeader) {
            onLeaderAction("selectSuspect", {suspect: e});
        }
    }
    const handleSetSelectedWeapon = (e: string) => {
        if (isLeader) {
            onLeaderAction("selectWeapon", {weapon: e})
        }
    }
    const handleSetSelectedRoom = (e: string) => {
        if (isLeader) {
            onLeaderAction("selectRoom", {room: e})
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
                <DialogTitle>Accusation Submission</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>Suspect</Typography>
                            <Select
                                fullWidth
                                value={selectedSuspect}
                                onChange={(e) => handleSetSelectedSuspect(e.target.value as string)}
                            >
                                {suspects.map((suspect, index) => (
                                    <MenuItem key={index} value={suspect}>{suspect}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Weapon</Typography>
                            <Select
                                fullWidth
                                value={selectedWeapon}
                                onChange={(e) => handleSetSelectedWeapon(e.target.value as string)}
                            >
                                {weapons.map((weapon, index) => (
                                    <MenuItem key={index} value={weapon}>{weapon}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Room</Typography>
                            <Select
                                fullWidth
                                value={selectedRoom}
                                onChange={(e) => handleSetSelectedRoom(e.target.value as string)}
                            >
                                {rooms.map((room, index) => (
                                    <MenuItem key={index} value={room}>{room}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="generic" disabled={!isLeader}>Cancel</Button>
                    <Button onClick={handleSubmit} color="generic" disabled={!isLeader}>Submit</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};


export default AccusationPopup;