import React, {useState, useEffect} from 'react';
import {
    Container, Typography, Button, Box, Grid,
} from '@mui/material';
import officeImage from './img/office-final.jpg';
import {theme} from '../../styling/palette';
import {ThemeProvider} from '@mui/material/styles';
import QuestionPopup from "./PopUp";
import {GameProps} from "../types";
import eventEmitter from "../../contexts/EventEmitter";
import MusicPlayer from "../../components/MusicPlayer";

const hygieneMusic = require('../../assets/Hygiene.mp3');

const musicSrcList: string[] =[hygieneMusic];

const questionsAndAnswers: { [key: string]: string } = {
    "Smart Thermostat": "A",
    "Router": "B",
    "Computer": "D",
    "Printer": "B",
    "Google Home": "C",
    "Smart Garbage Can": "A",
    "Nanny Cam": "B"
};


const HomeHygiene: React.FC<GameProps> = (
    {
        setInstructions,
        setTimeLimit,
        isInstructionPopupOpen,
        isLeader,
        leaderName,
        currQuestionIndex,
        gameScore,
        updateVoteMessage,
        onSubmit,
        onQuestionIndexUpdate,
        onLeaderAction,
        onComplete,
    }) => {


    const [attemptsUsed, setAttemptsUsed] = useState<number[]>(Array(7).fill(0));
    const [isQuestionPopupOpen, setIsQuestionPopupOpen] = useState(false);
    const [currQuestion, setCurrQuestion] = useState(-1);
    const [questionStatus, setQuestionStatus] = useState("neutral");
    const [selectedAnswer, setSelectedAnswer] = useState("");

    useEffect(() => {
        setInstructions({
            title: 'Home Hygiene Instructions',
            subtitle: 'Welcome to the room! Here are the instructions you need to follow:',
            content: [
            "1. Find 7 devices in the room that are connected to your home network, from your home office, that pose a security risk.",
            "2. Correctly answer the question about the best practice associated with the device.",
            "3. The more attempts you use, the less points you will get for answering the question.",
            "4. The game ends when all 7 devices are found and all questions correctly answered, or when time runs out.",
            "5. Alert the facilitator if you need assistance.",
            "",
            "The team leader controls what happens on-screen.",
            "Team members can see what the team leader does.",
            "",
            "Scoring: 1st try: 4 points, 2nd try: 2 points, 3rd try: 1 point, 4th try: 0 points",
        ]});

        setTimeLimit(8 * 60);
    }, []);

    useEffect(() => {
        const onQuestionChange = (data: { isCorrect: boolean; }) => {
            if (data.isCorrect) {
                console.log("within HH, CQI is: ", currQuestionIndex, " and marking currQ as completed");
                updateCurrQuestionIndex(currQuestion);
                setQuestionStatus("correct");
            } else {
                console.log("Got the question wrong. Current attempts:", attemptsUsed);
                setAttemptsUsed((prevAttempts) => {
                    const updatedAttempts = [...prevAttempts];
                    updatedAttempts[currQuestion] += 1;
                    return updatedAttempts;
                });
                setQuestionStatus("incorrect");
            }
        };

        eventEmitter.on('questionChange', onQuestionChange);


        return () => {
            eventEmitter.off('questionChange', onQuestionChange);
        };

    }, [currQuestionIndex, currQuestion, onQuestionIndexUpdate, attemptsUsed]);

    useEffect(() => {
        function leaderActionListener(action: string ) {
            console.log("leaderAction is: ", action);
            if (action === "close") {
                setIsQuestionPopupOpen(false);
                setCurrQuestion(-1);
                setQuestionStatus("neutral");
                setSelectedAnswer("");
            } else if (action.length === 1) {
                setSelectedAnswer(action);
            } else {
                // Open the question popup with the provided area (e.g., "Router", "Printer", etc.)
                setCurrQuestion(Object.keys(questionsAndAnswers).indexOf(action));
                setIsQuestionPopupOpen(true);
            }
        }

        eventEmitter.on('leaderAction', leaderActionListener);

        return () => {
            eventEmitter.off('leaderAction', leaderActionListener);
        };

    }, []);


    // The CQI (Current Question Index) is stored as an integer where each digit (0-6) represents
    // a question's completion status. We chose this to keep the game logic simple and standardized across rooms,
    // allowing easy tracking of progress by updating a single digit

    function getItemsRemaining(): number {
        const currQuestionIndexStr = currQuestionIndex.toString().padStart(7, '0');  // Ensure it's at least 7 digits long
        let count = 0;

        for (let i = 0; i < currQuestionIndexStr.length; i++) {
            if (currQuestionIndexStr[i] === '0') {
                count++;
            }
        }

        console.log("number of items remaining is: ", count);
        return count;
    }

    function updateCurrQuestionIndex(questionIndex: number): void {
        console.log("this QI is: ", questionIndex);
        const valueToAdd = 10 ** (6 - questionIndex);

        console.log("on question update in client, adding ", currQuestionIndex, " to ", valueToAdd);
        const newCurrQuestionIndex = currQuestionIndex + valueToAdd;
        onQuestionIndexUpdate(newCurrQuestionIndex);
    }

    function handleSubmit(question: number, answer: string): void {
        if (!isLeader) return;
        const questionKey = Object.keys(questionsAndAnswers)[question];

        updateVoteMessage(`${leaderName} has selected option ${answer}.`);

        let isCorrect = false;
        let scoreIncrement = 0;
        if (answer === questionsAndAnswers[questionKey]) {
            scoreIncrement = 4;
            isCorrect = true;

            const currAttempts = attemptsUsed[question];
            if (currAttempts === 1) {
                scoreIncrement = 2;
            } else if (currAttempts === 2) {
                scoreIncrement = 1;
            } else if (currAttempts >= 3) {
                scoreIncrement = 0;
            }
        }

        console.log("providing baseRoom with scoreIncrement of: ", scoreIncrement, " and curr gameScore is: ", gameScore);
        onSubmit(isCorrect, scoreIncrement);
    }

    function isQuestionCompleted(questionIndex: number): boolean {
        const currQuestionIndexStr = currQuestionIndex.toString().padStart(7, '0');  // Ensure it's at least 7 digits long
        return currQuestionIndexStr[questionIndex] === '1';
    }

    // emit message to open question popup
    function handleOpenQuestionPopup(area: string) {
        if (isLeader) {
            onLeaderAction(area);
        }
    }


    function handleSelectionChange(selection: string) {
        if (isLeader) {
            onLeaderAction(selection);
        }
    }



    // emit message to close question popup
    function handleCloseQuestionPopup() {
        if (isLeader) {
            onLeaderAction("close");
        }
    }



    return (
        <ThemeProvider theme={theme}>
            {musicSrcList && <MusicPlayer musicSrcList={musicSrcList} startPlay={!isInstructionPopupOpen} />}
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh', paddingTop: '5vh'}}>
                {getItemsRemaining() === 0 ? (
                    <Button
                        variant="contained"
                        color="generic"
                        onClick={() => onComplete()}
                        style={{display: 'block', margin: '20px auto', fontWeight: 'bold', textAlign: 'center'}}
                        data-cy="end-game-button"
                    >
                        You found the devices! Click here to escape the room!
                    </Button>
                ) : (
                    <Typography variant="h5" style={{fontWeight: 'bold', textAlign: 'center'}}>
                        Select 7 devices that are connected to your home network, from your home office, that pose a
                        cybersecurity risk.
                    </Typography>
                )}
                <Container disableGutters>
                    <Grid paddingTop={3} item xs={10} gap={5}
                          style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <Typography variant="h6">Score: {gameScore}</Typography>
                        <Typography variant="h6">Items remaining: {getItemsRemaining()} / 7</Typography>
                    </Grid>
                    <p style={{textAlign: "center"}}>
                        <div style={{
                            position: "relative",
                            display: "inline-block",
                            transition: "transform 150ms ease-in-out"
                        }}>
                            <img src={officeImage}/>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                 xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1208 684"
                                 style={{position: "absolute", top: 0, left: 0}}>

                                {/* Smart Thermostat */}
                                <a onClick={() => handleOpenQuestionPopup("Smart Thermostat")} style={{
                                    cursor: "pointer",
                                    pointerEvents: !isLeader || isQuestionCompleted(0) ? "none" : "auto"  // Check if the fourth bit (Smart Thermostat) is set
                                }} data-cy="thermostat-popup">
                                    <rect x="771" y="123" fill="#fff" opacity="0" width="59" height="56"></rect>
                                </a>

                                {/* Router */}
                                <a onClick={() => handleOpenQuestionPopup("Router")} style={{
                                    cursor: "pointer",
                                    pointerEvents: !isLeader || isQuestionCompleted(1) ? "none" : "auto"  // Check if the first bit (Router) is set
                                }} data-cy="router-popup">
                                    <rect x="422" y="137" fill="#fff" opacity="0" width="72" height="63"></rect>
                                </a>

                                {/* Computer */}
                                <a onClick={() => handleOpenQuestionPopup("Computer")} style={{
                                    cursor: "pointer",
                                    pointerEvents: !isLeader || isQuestionCompleted(2) ? "none" : "auto"  // Check if the third bit (Computer) is set
                                }} data-cy="computer-popup">
                                    <rect x="566" y="119" fill="#fff" opacity="0" width="195" height="171"></rect>
                                </a>

                                {/* Printer */}
                                <a onClick={() => handleOpenQuestionPopup("Printer")} style={{
                                    cursor: "pointer",
                                    pointerEvents: !isLeader || isQuestionCompleted(3) ? "none" : "auto"  // Check if the second bit (Printer) is set
                                }} data-cy="printer-popup">
                                    <rect x="906" y="483" fill="#fff" opacity="0" width="100" height="100"></rect>
                                </a>

                                {/* Google Home */}
                                <a onClick={() => handleOpenQuestionPopup("Google Home")} style={{
                                    cursor: "pointer",
                                    pointerEvents: !isLeader || isQuestionCompleted(4) ? "none" : "auto"  // Check if the fifth bit (Google Home) is set
                                }} data-cy="google-popup">
                                    <rect x="492" y="226" fill="#fff" opacity="0" width="54" height="77"></rect>
                                </a>

                                {/* Smart Garbage Can */}
                                <a onClick={() => handleOpenQuestionPopup("Smart Garbage Can")} style={{
                                    cursor: "pointer",
                                    pointerEvents: !isLeader || isQuestionCompleted(5) ? "none" : "auto"  // Check if the seventh bit (Smart Garbage Can) is set
                                }} data-cy="garbagecan-popup">
                                    <rect x="983" y="392" fill="#fff" opacity="0" width="100" height="88"></rect>
                                </a>

                                {/* Nanny Cam */}
                                <a onClick={() => handleOpenQuestionPopup("Nanny Cam")} style={{
                                    cursor: "pointer",
                                    pointerEvents: !isLeader || isQuestionCompleted(6) ? "none" : "auto"  // Check if the sixth bit (Nanny Cam) is set
                                }} data-cy="nannycam-popup">
                                    <rect x="125" y="374" fill="#fff" opacity="0" width="78" height="60"></rect>
                                </a>


                                {/* Circles indicating completion (visibility based on CQI) */}
                                <circle r={29.5} cx={800.5} cy={151} fill="none" stroke="green" strokeWidth="2"
                                        visibility={isQuestionCompleted(0) ? "visible" : "hidden"} />  {/* Smart Thermostat */}
                                <circle r={36} cx={458} cy={168.5} fill="none" stroke="green" strokeWidth="2"
                                        visibility={isQuestionCompleted(1) ? "visible" : "hidden"} />  {/* Router */}
                                <circle r={97.5} cx={663.5} cy={204.5} fill="none" stroke="green" strokeWidth="2"
                                        visibility={isQuestionCompleted(2) ? "visible" : "hidden"} />  {/* Computer */}
                                <circle r={50} cx={956} cy={533} fill="none" stroke="green" strokeWidth="2"
                                        visibility={isQuestionCompleted(3) ? "visible" : "hidden"} />  {/* Printer */}
                                <circle r={38.5} cx={519} cy={264.5} fill="none" stroke="green" strokeWidth="2"
                                        visibility={isQuestionCompleted(4) ? "visible" : "hidden"} />  {/* Google Home */}
                                <circle r={50} cx={1033} cy={436} fill="none" stroke="green" strokeWidth="2"
                                        visibility={isQuestionCompleted(5) ? "visible" : "hidden"} />  {/* Smart Garbage Can */}
                                <circle r={39} cx={164} cy={404} fill="none" stroke="green" strokeWidth="2"
                                        visibility={isQuestionCompleted(6) ? "visible" : "hidden"} />  {/* Nanny Cam */}
                            </svg>
                        </div>
                    </p>

                    <QuestionPopup open={isQuestionPopupOpen}
                                   isLeader={isLeader}
                                   question={currQuestion}
                                   questionStatus={questionStatus}
                                   selectedAnswer={selectedAnswer}
                                   selectionChange={handleSelectionChange}
                                   onSubmit={handleSubmit}
                                   onClose={handleCloseQuestionPopup}/>
                </Container>
            </Box>
        </ThemeProvider>
    )
}

export default HomeHygiene;