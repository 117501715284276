import React, {useState} from 'react';
import {Container, Box, Typography, TextField, Button, LinearProgress} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSocket} from '../contexts/SocketContext';
import {theme} from '../styling/palette';
import {ThemeProvider} from '@mui/material/styles';
import Background from './Background'; // Assuming Background component is the same
import {useTeam} from "../contexts/TeamContext";
import axios from "axios";


const EnterUsernameRoom = () => {

    const navigate = useNavigate();
    const {userName, setUserName, teamName, setCurrGame, setGameStarted} = useTeam();
    const {socket, submitUserName} = useSocket();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        setErrorMessage('');

        const { success, error, currGame } = await submitUserName(userName);


        if (success && currGame !== undefined && socket) {

            socket.emit('checkGameStatus', async (response: { gameStarted: boolean; }) => {
                if (response.gameStarted) {
                    setGameStarted(true);
                }

                console.log("within handle Submit in usernameRoom, currGame is: ", currGame, " and gameStarted is: ", response.gameStarted);

                if (currGame === 0 || !response.gameStarted) {
                    setCurrGame(0);
                    navigate('/game');
                } else {
                    setCurrGame(currGame);
                    await axios.put('/api/update-room', {
                        sid: socket.id,
                        room: -1,
                        teamName: teamName
                    })

                    navigate('/lobby');
                }
            });


        } else {
            setErrorMessage(error || 'Unexpected error occurred.');
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                position: 'relative',
            }}>
                <Background/>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: {xs: '100%', md: '50%'},
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                    background: theme.palette.generic.main,
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        textAlign: 'center',
                        mb: 4,
                    }}>
                        <Typography variant="h4" sx={{color: 'white', fontWeight: 'bold', mt: 2}}>
                            Welcome to the Company ABC Escape Room!
                        </Typography>
                    </Box>
                    <Container maxWidth="sm" sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        background: 'white',
                        padding: 4,
                        borderRadius: 2,
                        width: '80%',
                        maxWidth: '400px',
                    }}>
                        <Typography variant="h5" align="center" gutterBottom data-cy="email-label">
                            Please Enter Your Player Name
                        </Typography>
                        <form onSubmit={handleSubmit} data-cy="email-form">
                            <TextField
                                label="player name"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                error={!!errorMessage}
                                helperText={errorMessage}
                                data-cy="playername-input"
                            />
                            <Button type="submit" variant="contained" color='generic' fullWidth
                                    data-cy="continue-button">
                                Continue
                            </Button>
                            <LinearProgress style={{visibility: loading ? "visible" : "hidden"}} color='generic'/>
                        </form>
                    </Container>
                </Box>
                {/* <Box sx={{ mt: 'auto', width: '100%' }}>
          <Logos />
        </Box> */}
            </Box>
        </ThemeProvider>
    );
};

export default EnterUsernameRoom;
