import React, {useEffect, useRef, useState} from 'react';
import {start} from "repl";

interface MusicPlayerProps {
    musicSrcList: string[] | null;  // Path to the music file for this room
    startPlay: boolean;
}

const MusicPlayer: React.FC<MusicPlayerProps> = ({ musicSrcList, startPlay }) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [currTrackIndex, setCurrTrackIndex] = useState(0);

    useEffect(() => {
        if (!musicSrcList || musicSrcList.length === 0 || !startPlay) return;

        // Create a new audio element and set it to loop
        const audio = new Audio(musicSrcList[currTrackIndex]);
        audio.volume = 0.15;
        audio.loop = musicSrcList.length === 1;
        audioRef.current = audio;

        const playAudio = () => {
            audio.play().catch((error) => {
                console.error("Error playing audio:", error);
            });
        };

        playAudio();

        const handleTrackEnd = () => {
            if (musicSrcList.length > 1) {
                setCurrTrackIndex((prevIndex) => (prevIndex + 1) % musicSrcList.length);
            }
        };

        audio.addEventListener('ended', handleTrackEnd);


        return () => {
            // Fade out and clean up audio when component unmounts or track changes
            if (audioRef.current) {
                let fadeAudio = setInterval(() => {
                    if (audioRef.current && audioRef.current.volume > 0.01) {
                        audioRef.current.volume = Math.max(0, audioRef.current.volume - 0.01);
                    } else {
                        if (audioRef.current) {
                            audioRef.current.pause();
                            audioRef.current.removeEventListener('ended', handleTrackEnd);
                        }
                        clearInterval(fadeAudio);
                        audioRef.current = null;
                    }
                }, 1000 / 15); // Gradually reduce volume in steps
            }
        };
    }, [musicSrcList, currTrackIndex, startPlay]);

    return null; // This component does not need to render anything in the DOM
};

export default MusicPlayer;
