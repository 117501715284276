import {styled} from "@mui/material/styles";
import {Button, Card} from "@mui/material";

export interface MyToken {
    role: string;
    teamName: string;
}

export const StyledCard = styled(Card)(({theme}) => ({
    padding: theme.spacing(2),
}));

export const StyledForm = styled('form')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

export const SubmitButton = styled(Button)(({theme}) => ({
    marginTop: theme.spacing(2),
    flexGrow: 1,
}));

export interface InstructionsType {
    title: string;
    subtitle: string;
    content: React.ReactNode[];
}
