import React, {useState, useEffect} from 'react';
import {
    Container, Typography, Button, Box, Grid,
    IconButton, Card, CardContent, LinearProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {theme} from '../../styling/palette';
import {ThemeProvider} from '@mui/material/styles';

// all the clue images

import clue1and8 from './img/clue1.png';
import clue2and7 from './img/clue2.png';
import clue3 from './img/clue3.png';
import clue4 from './img/clue4.png';
import clue5 from './img/clue5.png';
import clue6 from './img/clue6.png';
import clue9 from './img/clue9.png';
import clue10 from './img/clue10.png';
import clue11 from './img/clue11.png';
import blankClue from './img/blankclue.png';
import suspectsImage from './img/suspectsImage.png'
import FeedbackDialog from './components/FeedbackDialog';
import DropdownTable from "./components/Table";
import AccusationPopup from "./components/AccusationPopup";
import {GameProps} from "../types";
import eventEmitter from "../../contexts/EventEmitter";
import MusicPlayer from "../../components/MusicPlayer";

const insiderMusic =  require('../../assets/Insider.mp3');
const musicSrcList: string[] =[insiderMusic];

const suspects = ["Alice", "Bob", "Eve", "Mallory", "Oscar", "Trent"];

const suspectDescriptions = [
    {
        name: "Alice",
        description: "Disgruntled employee spreading company rumours."
    },
    {
        name: "Bob",
        description: "Recent hire who knows more than he should about the company."
    },
    {
        name: "Eve",
        description: "Data Analyst who feels underappreciated."
    },
    {
        name: "Malory",
        description: "Contractor with privileged access."
    },
    {
        name: "Oscar",
        description: "System administrator with too much power."
    },
    {
        name: "Trent",
        description: "Software developer paying top dollar for rent."
    }
];


const weapons = ["USB Drive", "Keylogger", "Rootkit", "Zero-Day Exploit", "Backdoor", "Unauthorized Access"];
const rooms = ["CEO's Office", "CFO's Office", "HR's Office", "Reception", "Games Room", "Kitchen"];

// All the clues for the game
const clues = [
    "Clue 1: Alice was seen near the CEO's office but does not have the knowledge to use a rootkit.",
    "Clue 2: Bob was not in the kitchen on the day of the leak and does not know how to use a zero-day exploit.",
    "Clue 3: Eve was spotted in the reception area but has no access to stolen credentials.",
    "Clue 4: Mallory was working in the CFO's office but has no experience with a key logger.",
    "Clue 5: Oscar was not near the games room but is proficient with rootkit attacks.",
    "Clue 6: Trent was seen in the kitchen but has no means to perform a zero-day exploit.",
    "Clue 7: Bob was seen in the games room on the day of the leak.",
    "Clue 8: Alice has been known to shoulder surf (watch colleagues type their password) but was never in the CFO's office.",
    "Clue 9: There was a laptop found in the games room that was logged in with the credentials of a user that was away on vacation.",
    "Clue 10: The janitor reported that he found a sticky note with a password written on it crumpled up under Bob's desk.",
    "Clue 11: Bob handed in his resignation a week later, saying he suddenly came into a windfall of money.",
    "All clues have been shown. Please make your decision now."
];


const images = [
    clue1and8, clue2and7, clue3, clue4, clue5, clue6, clue2and7, clue1and8, clue9, clue10, clue11, blankClue
]

export interface FormData {
    [key: string]: string; // This allows us to use arbitrary strings as keys in formData
}

// Using the contexts e.g., SocketContext and TeamContext to allow them to persist throughout the games
const InsiderInsightsGame: React.FC<GameProps> = (
    {
        setInstructions,
        setTimeLimit,
        isLeader,
        leaderName,
        isInstructionPopupOpen,
        currQuestionIndex,
        onSubmit,
        updateVoteMessage,
        onQuestionIndexUpdate,
        onLeaderAction,
        onComplete,
    }) => {
    const [currentClueIndex, setCurrentClueIndex] = useState(0);
    const [finishIntro, setFinishIntro] = useState(false);
    const [maxClueIndex, setMaxClueIndex] = useState(0);
    const [isAccusationPopupOpen, setIsAccusationPopupOpen] = useState(false);
    const [accusationCount, setAccusationCount] = useState(0);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [displayString, setDisplayString] = useState("");

    const [selectedSuspect, setSelectedSuspect] = useState('');
    const [selectedWeapon, setSelectedWeapon] = useState('');
    const [selectedRoom, setSelectedRoom] = useState('');

    const [formData, setFormData] = useState<FormData>({});

    useEffect(() => {
        setInstructions({
            title: 'Insider Insights Instructions',
            subtitle: 'Welcome to the room! Here are the instructions you need to follow:',
            content: [
            <span><b>1. Read the clues:</b></span>,
            "Each clue will help you determine which Suspect matches with which Room and Weapon, and which Room matches which Weapon.",
            "",
            "The clues may be direct (e.g., “Alice is not in the office”) or indirect (e.g., “The person in the kitchen is not Bob”).",
            "",
            <span><b>2. Mark what you know:</b></span>,
            "Use symbols like an \"X\" to indicate what is not true and a checkmark “Y\" for what is true.\n",
            "Example:",
            "If the clue is \"Alice is not in HR’s Office,\" put an \"X\" where Alice and HR’s Office intersect.",
            "",
            "Use the intersections of Suspects, Rooms and Weapons to make your accusation."
        ]});

        setTimeLimit(8 * 60);
    }, []);

    useEffect(() => {


        const preloadNextImage = () => {
            if (images[currentClueIndex + 1]) {
                const nextImg = new Image();
                nextImg.src = images[currentClueIndex + 1];
            }
        };

        preloadNextImage();


    }, [currentClueIndex]);

    useEffect(() => {
        if (currQuestionIndex !== 0) {
            onComplete();
        }
    }, [currQuestionIndex, onComplete]);

    useEffect(() => {

        function createDisplayMessage() {
            let string = `Oops, your accusation was incorrect! `;
            console.log("selected - suspect: ", selectedSuspect, ", weapon: ", selectedWeapon, ", room: ", selectedRoom);
            if (selectedSuspect === "Bob" || selectedWeapon === "Unauthorized Access" || selectedRoom === "Games Room") {
                string = string + `But...\n`;
                if (selectedSuspect === "Bob") {
                    string = string + `You got the suspect right!\n`
                }
                if (selectedWeapon === "Unauthorized Access") {
                    string = string + `You got the weapon right!\n`
                }
                if (selectedRoom === "Games Room") {
                    string = string + `You got the room right!\n`
                }
            }
            string = string + `Please try again.`;

            return string;
        }

        const onQuestionChange = (data: { isCorrect: boolean }) => {
            if (data.isCorrect) {
                onQuestionIndexUpdate(currQuestionIndex + 1);
                onComplete();
            } else {
                const message = createDisplayMessage();
                setDisplayString(message);
                setFeedbackOpen(true);
            }
        };

        eventEmitter.on('questionChange', onQuestionChange);


        return () => {
            eventEmitter.off('questionChange', onQuestionChange);
        };

    }, [currQuestionIndex, onQuestionIndexUpdate, selectedSuspect, selectedWeapon, selectedRoom]);


    useEffect(() => {
        function leaderActionListener(action: string, data: any) {
            console.log("leaderAction is: ", action);

            if (action === "openAccusationPopup") {

                console.log("received command to open accusation popup");
                setIsAccusationPopupOpen(true);
            } else if (action === "closeAccusationPopup") {
                setIsAccusationPopupOpen(false);
            } else if (action === 'closeFeedback') {
                setFeedbackOpen(false);
            } else if (action === 'clueIndex') {
                const clueIndex = data.clueIndex;
                setCurrentClueIndex(clueIndex);
                if (clueIndex > maxClueIndex) {
                    setMaxClueIndex(clueIndex);
                }
            } else if (action === 'selectSuspect') {
                setSelectedSuspect(data.suspect);
            } else if (action === 'selectWeapon') {
                setSelectedWeapon(data.weapon);
            } else if (action === 'selectRoom') {
                setSelectedRoom(data.room);
            } else {
                setFormData((prev) => ({ ...prev, [data.fieldId]: data.value }));
            }
        }
        eventEmitter.on('leaderAction', leaderActionListener);

        return () => {
            eventEmitter.off('leaderAction', leaderActionListener);
        };

    }, [currQuestionIndex, currentClueIndex, maxClueIndex]);


    const handleNextClue = () => {
        if (isLeader) {
            onLeaderAction('clueIndex', {clueIndex: currentClueIndex + 1});
        }
    };

    const handlePrevClue = () => {
        if (isLeader) {
            onLeaderAction('clueIndex', {clueIndex: currentClueIndex - 1});
        }
    }

    // Checking if they get the correct answer
    async function handleAccusation(suspect: string, weapon: string, room: string) {
        if (!isLeader) return;
        updateVoteMessage(`${leaderName} selects that ${suspect} utilized a ${weapon} in the ${room}.`);

        onLeaderAction('closeAccusationPopup');
        setAccusationCount((prevCount) => prevCount + 1);

        let isCorrect = false;
        let scoreIncrement = 0;

        if (suspect === "Bob" && weapon === "Unauthorized Access" && room === "Games Room") {
            scoreIncrement = calculateScore();
            isCorrect = true;

        }

        onSubmit(isCorrect, scoreIncrement);

    }


    // Score calculation as provided in the game flow. Base score of 20 - n where n is the number of incorrect accusations
    // + bonus points for the time + bonus points for the number of clue cards not clicked
    function calculateScore() {
        let finalScore = 0;
        const cluesLeft = clues.length - maxClueIndex - 1;


        if (cluesLeft >= 6) {
            finalScore += 2;
        } else if (cluesLeft >= 3) {
            finalScore += 1;
        }

        if (accusationCount === 0) {
            finalScore += 10;
        } else if (accusationCount === 1 || accusationCount === 2) {
            finalScore += 8;
        } else if (accusationCount === 3) {
            finalScore += 6;
        } else if (accusationCount === 4) {
            finalScore += 3;
        } else if (accusationCount === 5) {
            finalScore += 1;
        } else if (accusationCount >= 7) {
            finalScore += 0;
        }


        return finalScore;
    }


    const handleOpenAccusationPopup = () => {
        if (!isLeader) return;
        onLeaderAction('openAccusationPopup');
    }

    const handleCloseAccusationPopup = () => {
        if (!isLeader) return;
        onLeaderAction('closeAccusationPopup');
    }

    const onCloseFeedback = () => {
        if (!isLeader) return;
        onLeaderAction('closeFeedback');
    }

    // Game UI
    return (
        <ThemeProvider theme={theme}>
            {musicSrcList && <MusicPlayer musicSrcList={musicSrcList} startPlay={!isInstructionPopupOpen} />}
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                {finishIntro ? (
                    <><Container maxWidth="lg" sx={{flex: '1 0 auto'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={1}>
                                    <img src={images[currentClueIndex]} alt="Suspects"
                                         style={{width: 'auto', height: '350px'}}/>

                                </Box>
                                <Box display="flex" justifyContent="center" mt={-3}>
                                    <Card style={{
                                        width: '60%',
                                        padding: '10px',
                                        textAlign: 'center',
                                        backgroundColor: '#ffffff',
                                        borderRadius: '40px',
                                        border: '2px solid black',
                                        clipPath: 'none',
                                        boxShadow: '0px 0px 0px 3px black',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <CardContent>
                                            <Typography variant="body1" align="center">
                                                {clues[currentClueIndex]}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Box>
                                <Box display="flex" justifyContent="center" mt={3} gap={3}>
                                    <IconButton color="generic" onClick={handlePrevClue}
                                                disabled={currentClueIndex === 0}
                                                style={{
                                                    fontSize: '20px',
                                                    border: '2px solid',
                                                    padding: '10px',
                                                    borderRadius: '10px'
                                                }}>
                                        <SearchIcon/>
                                        <Typography variant="body2" sx={{marginLeft: '5px'}}>Previous Clue</Typography>
                                    </IconButton>
                                    <IconButton color="generic" onClick={handleNextClue}
                                                disabled={currentClueIndex === clues.length - 1} style={{
                                        fontSize: '20px',
                                        border: '2px solid',
                                        padding: '10px',
                                        borderRadius: '10px'
                                    }}>
                                        <SearchIcon/>
                                        <Typography variant="body2" sx={{marginLeft: '5px'}}>Next Clue</Typography>
                                    </IconButton>
                                </Box>
                                <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={3}>
                                    <LinearProgress variant="determinate" color="generic"
                                                    value={maxClueIndex >= (clues.length) ? 100 : (100 * ((maxClueIndex + 1) / (clues.length)))}
                                                    style={{width: '50%'}}/>
                                    <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={3}>
                                        <Typography variant="body2" style={{textAlign: 'center', whiteSpace: 'nowrap'}}>Clues
                                            used: {maxClueIndex + 1} / {clues.length} </Typography>
                                    </Box>
                                </Box>

                                <Box display="flex" justifyContent="center" mt={3}>
                                    <Button variant="contained" color="generic" onClick={handleOpenAccusationPopup}>
                                        Make an Accusation
                                    </Button>
                                </Box>
                                <Box display="flex" justifyContent="center" mt={3}>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column'}}>
                                <DropdownTable
                                    isLeader={isLeader}
                                    suspects={suspects}
                                    weapons={weapons}
                                    rooms={rooms}
                                    formData={formData}
                                    onLeaderAction={onLeaderAction}/>
                            </Grid>
                        </Grid>

                        <AccusationPopup
                            open={isAccusationPopupOpen}
                            isLeader={isLeader}
                            onClose={handleCloseAccusationPopup}
                            onSubmit={handleAccusation}
                            onLeaderAction={onLeaderAction}
                            suspects={suspects}
                            weapons={weapons}
                            rooms={rooms}
                            selectedSuspect={selectedSuspect}
                            selectedWeapon={selectedWeapon}
                            selectedRoom={selectedRoom}/>
                    </Container>
                        <FeedbackDialog open={feedbackOpen} isLeader={isLeader} onClose={onCloseFeedback}
                                                msg={displayString}/>
                    </>
                    ) : (
                    <>
                        <Container maxWidth="lg" sx={{flex: '1 0 auto'}}>
                        <Typography variant="h4" gutterBottom align="center" mt={5}>
                            Meet the Suspects
                        </Typography>
                        <Typography variant="body1" align="center" paragraph>
                            It is suspected that a trusted employee has been stealing sensitive data from your company.
                            It has been narrowed down to six suspects. Your team must piece together the evidence and
                            determine who has been stealing the sensitive data, and how they did it.
                        </Typography>

                        <Box display="flex" justifyContent="center" mt={3} mb={1}>
                            <img src={suspectsImage} alt="Suspects" style={{maxWidth: '100%', height: 'auto'}}/>
                        </Box>

                        <Grid container spacing={2} justifyContent="center">
                            {suspectDescriptions.map((suspect, index) => (
                                <Grid item xs={12} sm={4} md={2} key={index} style={{textAlign: 'center'}}>
                                    <Typography variant="h6">{suspect.name}</Typography>
                                    <Typography variant="body2" color="textSecondary">{suspect.description}</Typography>
                                </Grid>
                            ))}
                        </Grid>

                        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                            <Button variant="contained" color="generic" onClick={() => setFinishIntro(true)}>
                                Continue
                            </Button>
                        </Box>
                    </Container>
                        </>

                )}
            </Box>


        </ThemeProvider>
    );
};

export default InsiderInsightsGame;
