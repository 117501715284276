import React, {useState, useEffect} from 'react';
import {
    Typography,
    Box,
    CardContent,
    AppBar,
    Toolbar,
    Button,
    Snackbar,
    Alert
} from '@mui/material';
import {jwtDecode} from "jwt-decode";

import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {useSocket} from '../contexts/SocketContext';
import {useTeam} from '../contexts/TeamContext';
import {theme} from '../styling/palette';
import Logos from '../components/Logos';
import TeamList from '../components/TeamList';
import StaticLeaderboard from '../components/StaticLeaderboard';
import {gameComponents} from "../rooms/GameRenderer";
import lobby1 from '../assets/lobby1.png';
import lobby2 from '../assets/lobby2.png';
import lobby3 from '../assets/lobby3.png';
import lobby4 from '../assets/lobby4.png';
import lobby5 from '../assets/lobby5.png';
import lobby6 from '../assets/lobby6.png';

import axios from "axios";
import {MyToken, StyledCard, StyledForm, SubmitButton} from "./types";

const Lobby = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const navigate = useNavigate();
    const {socket, isLoggedIn} = useSocket();
    const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
    const {teamName, currGame, setTeamName, gameStarted, setGameStarted} = useTeam();
    const [accessGranted, setAccessGranted] = useState(false);
    const [alerted, setAlerted] = useState(false);

    const lobbyImages: {[key: number]: string} = {
        1: lobby1,
        2: lobby2,
        3: lobby3,
        4: lobby4,
        5: lobby5,
        6: lobby6
    };




    useEffect(() => {
        if (socket && isLoggedIn) {
            socket.emit("randomSelect", {game: currGame, sid: socket.id});
        }
    }, [socket, isLoggedIn, currGame]);


    useEffect(() => {

        if (accessGranted && socket) {

            const sid = socket.id;
            axios.put('/api/update-room', {
                sid: sid,
                room: currGame,
                teamName: teamName
            }).then((response) => {
                if (response.data.success) {

                    if (!gameComponents[currGame]) {
                        navigate('/finale-game');
                    } else {
                        console.log('Room updated successfully');
                        navigate('/game');
                    }
                } else {
                    console.log('Error updating room');
                }
            }).catch((error) => {
                console.error('Error updating room:', error);
            });


        }

        if (socket) {
            socket.on('broadcastMessage', (data) => {
                setSnackbarMessage(data.message);
                setSnackbarOpen(true);
            });


            return () => {
                socket.off('broadcastMessage');
            };
        }
    }, [socket, accessGranted, navigate, setTeamName]);

    const handleAlertFacilitator = () => {
        if (socket) {
            socket.emit('alertFacilitator', {teamName});
            setAlerted(true);
            setTimeout(() => {
                setAlerted(false);
            }, 5000)
        }
    };

    const handleNoPasswordSubmit = (e: any) => {
        e.preventDefault();
        setAccessGranted(true);
    }

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <AppBar style={{top: 0}} position="sticky" color="generic">
                    <Toolbar style={{justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h6" component="div" sx={{mr: 2}}>
                                Escape Room Lobby
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center', flexGrow: 1}} gap={2}>
                            <TeamList isLeader={false}/>
                            <Button variant="contained" color="generic" onClick={handleAlertFacilitator}
                                    sx={{width: 200, ml: 2}}>
                                {alerted ? "Facilitator Alerted" : "Alert Facilitator"}
                            </Button>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h6" component="div" sx={{mr: 2}} visibility={"hidden"}>
                                Escape Room Lobby
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>

                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2)}}>
                    <img src={lobbyImages[currGame]} alt="Lobby" style={{width: '100%', maxHeight: '300px', objectFit: 'cover'}}/>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    padding: theme.spacing(2),
                    flexGrow: 1
                }}>
                    <Box sx={{
                        flex: 1,
                        marginRight: theme.spacing(2),
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'stretch'
                    }}>
                        <StyledCard
                            sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <CardContent>
                                <StaticLeaderboard refresh={refreshLeaderboard}/>
                            </CardContent>
                        </StyledCard>
                    </Box>

                    <Box sx={{
                        flex: 1,
                        marginLeft: theme.spacing(2),
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'stretch'
                    }}>
                        <StyledCard
                            sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom textAlign={"center"}>
                                    {gameStarted ?
                                        (currGame === 1 ?
                                            "Start the Game!" :
                                            "Advance to the Next Room") :
                                        "Please wait for the facilitator to start the game"}
                                </Typography>
                                <StyledForm onSubmit={handleNoPasswordSubmit}>
                                    <SubmitButton variant="contained" color="generic"
                                                  onClick={handleNoPasswordSubmit}
                                                  data-cy="continue-button"
                                                  disabled={!gameStarted}>
                                        Continue
                                    </SubmitButton>
                                </StyledForm>
                            </CardContent>
                        </StyledCard>
                    </Box>
                </Box>
                <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto'}}>
                    <Logos/>
                </Box>
            </Box>

            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={snackbarOpen}
                autoHideDuration={null}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default Lobby;
