import React, {useState, useEffect, useRef} from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    IconButton,
    LinearProgress,
    Dialog,
    DialogContent,
    DialogActions
} from '@mui/material';
import {ThemeProvider, styled} from '@mui/material/styles';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ReactAudioPlayer from 'react-audio-player';
import {GameProps} from "../types";
import eventEmitter from "../../contexts/EventEmitter";
import {theme} from "../../styling/palette";

// all the clips here with the answers and the explanations

const audioClips = [
    {
        src: require('./audio/Clip1.mp3'),
        isScam: true,
        explanation: 'Core Concept: Exercise caution when sharing sensitive information over the phone. Banks will never request details like your account number or PIN. Scammers exploit their victims trust and sense of urgency to obtain sensitive information and steal money from their accounts.​'
    },
    {
        src: require('./audio/Clip2.mp3'),
        isScam: true,
        explanation: 'Core Concept: Exercise caution when sharing sensitive information over the phone. Banks will never request details like your account number or PIN. Scammers exploit their victims trust and sense of urgency to obtain sensitive information and steal money from their accounts.​'
    },
    {
        src: require('./audio/Clip3.mp3'),
        isScam: false,
        explanation: 'Core Concept: The caller does not ask for any personal information, and only asks for them to confirm what they have on file is accurate.'
    },
    {
        src: require('./audio/Clip4.mp3'),
        isScam: false,
        explanation: 'Core Concept: The caller does not ask for any personal information, and only asks for them to confirm what they have on file is accurate.'
    },
    {
        src: require('./audio/Clip5.mp3'),
        isScam: true,
        explanation: 'Core Concept: Exercise caution when sharing sensitive information over the phone. The caller has asked for a variety of personally identifiable information for identity theft and fraudulent activities.​'
    },
    {
        src: require('./audio/Clip6.mp3'),
        isScam: false,
        explanation: 'Core Concept:  The caller does not ask for any personal information or relay information that would be harmful to the individual.'
    },
    {
        src: require('./audio/Clip7.mp3'),
        isScam: true,
        explanation: 'Core Concept: The call expresses a sense of urgency and indicates legal action would be taken for non-compliance. A good practice if you are unsure if a caller is legitimate is to call them back on a known phone number prior to giving them any sensitive information.​'
    },
    {
        src: require('./audio/Clip8.mp3'),
        isScam: false,
        explanation: 'Core Concept:  The caller does not ask for the full credit card number. Providing the last four digits of a credit card is not enough information for an individual to maliciously use your credit card. Additionally, they provide information regarding the fraudulent purchase, and direct the customer to contact them using means that can be verified to be secure.​'
    },
    {
        src: require('./audio/Clip9.mp3'),
        isScam: true,
        explanation: 'Core Concept:  The caller asks for personally identifiable information as well as to pay a fine immediately. Any links sent to you by email should be verified for authenticity prior to providing any sensitive information.​'
    },
    {
        src: require('./audio/Clip10.mp3'),
        isScam: true,
        explanation: 'Core Concept:  The caller asks for personally identifiable information, including full credit card details. Often when a deal is “too good to be true” and only available to you, it may in fact, not be true. ​'
    },
];

// UI stuff
const PlayPauseButton = styled(Button)(({theme}) => ({
    backgroundColor: theme.palette.generic.main,
    color: theme.palette.generic.contrastText,
    marginTop: '10px',
    '&:hover': {
        backgroundColor: theme.palette.generic.dark,
    },
}));

const GuessButton = styled(Button)(({theme}) => ({
    margin: '0 10px',
    padding: '15px 25px',
    backgroundColor: theme.palette.generic.main,
    color: theme.palette.generic.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.generic.dark,
    },
}));

const PhantomLines: React.FC<GameProps> = (
    {
        setInstructions,
        setTimeLimit,
        isLeader,
        leaderName,
        currQuestionIndex,
        gameScore,
        updateVoteMessage,
        onSubmit,
        onQuestionIndexUpdate,
        onLeaderAction,
        onComplete
    }
) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlayDisabled, setIsPlayDisabled] = useState(false);
    const audioPlayerRef = useRef<ReactAudioPlayer>(null);
    const [audioProgress, setAudioProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [explanationOpen, setExplanationOpen] = useState(false);
    const [guessResult, setGuessResult] = useState<'Correct' | 'Incorrect'>('Correct');

    useEffect(() => {
        setInstructions({
            title: 'Phantom Lines Instructions',
            subtitle: 'Welcome to the room! Here are the instructions you need to follow:',
            content: [
            "1. Listen to the audio clip and decide if it's a scam or real.",
            "2. Use the buttons to make your guess.",
            "3. If your guess is correct, you'll earn points.",
            "4. The game ends when all clips are played or time runs out.",
            "5. Alert the facilitator if you need assistance.",
            "",
            "The team leader controls what happens on-screen.",
            "Other players can see what the team leader does.",
            "",
            "Scoring: 2 points for correct answer. Plus 1 bonus point if there is more than 20 seconds remaining in the clip",
        ]});

        setTimeLimit(8 * 60);
    }, []);

    useEffect(() => {
        if (currQuestionIndex === audioClips.length && !explanationOpen) {
            onComplete();
        }
    }, [currQuestionIndex, explanationOpen]);

    useEffect(() => {
        const onQuestionChange = (data: { isCorrect: boolean; }) => {

            setIsPlaying(false);
            if (data.isCorrect) {
                console.log("within PL, CQI is: ", currQuestionIndex, " and marking currQ as completed");
                setGuessResult('Correct');

            } else {
                console.log("Got the question wrong.");
                setGuessResult('Incorrect');

            }
            onQuestionIndexUpdate(currQuestionIndex + 1);

            setExplanationOpen(true);
            setAudioProgress(0); // Reset progress bar for the new clip
            setCurrentTime(0); // Reset current time for the new clip
            setIsPlayDisabled(false); // Reset play button for the new clip


        };

        eventEmitter.on('questionChange', onQuestionChange);

        return () => {
            eventEmitter.off('questionChange', onQuestionChange);
        };

    }, [currQuestionIndex]);


    useEffect(() => {

        function leaderActionListener(action: string ) {
            if (action === 'play') {
                const player = audioPlayerRef.current?.audioEl.current;
                if (player) {
                    player.play().then(() => {
                        setIsPlaying(true);
                    }).catch((error: any) => {
                        console.error('Error playing audio:', error);
                    });
                }
            } else if (action === 'pause') {
                const player = audioPlayerRef.current?.audioEl.current;
                if (player) {
                    player.pause();
                    setIsPlaying(false);
                }
            } else if (action === 'close') {

                setExplanationOpen(false);
            }

        }

        eventEmitter.on('leaderAction', leaderActionListener);

        return () => {
            eventEmitter.off('leaderAction', leaderActionListener);
        };

    }, [currQuestionIndex]);


    useEffect(() => {
        // handles audio playing
        const player = audioPlayerRef.current?.audioEl.current;
        const handleSeeking = (e: any) => {
            e.preventDefault();
            if (player) {
                player.currentTime = 0;
            }
        };

        if (player) {
            player.addEventListener('seeking', handleSeeking);
            player.addEventListener('seeked', handleSeeking);
        }

        return () => {
            if (player) {
                player.removeEventListener('seeking', handleSeeking);
                player.removeEventListener('seeked', handleSeeking);
            }
        };
    }, [currQuestionIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPlaying) {
                handleAudioTimeUpdate();
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [isPlaying]);


    const handlePlay = () => {

        if (isLeader) {
            setIsPlaying(true);
            onLeaderAction('play');
        }
    };

    const handlePause = () => {
        if (isLeader) {
            onLeaderAction('pause');
        }
    };

    const handleGuess = async (userGuess: string) => {
        if (!isLeader) return;

        updateVoteMessage(`${leaderName} has selected that this line is a ${userGuess}.`);

        let isCorrect = false;
        let scoreIncrement = 0;
        if (userGuess === (audioClips[currQuestionIndex].isScam ? 'SCAM' : 'REAL')) {
            scoreIncrement = 2;
            isCorrect = true;

            const player = audioPlayerRef.current?.audioEl.current;

            let timeRemaining = 0;
            if (player) {
                timeRemaining = player.duration - player.currentTime;
            }

            if (timeRemaining > 20) {
                scoreIncrement++;
            }

        }

        console.log("providing baseRoom with scoreIncrement of: ", scoreIncrement, " and curr gameScore is: ", gameScore);
        onSubmit(isCorrect, scoreIncrement);

    }

    const handleExplanationClose = () => {
        setExplanationOpen(false);

        if (isLeader) {
            onLeaderAction('close');
        }

    };


    const handleAudioTimeUpdate = () => {
        const player = audioPlayerRef.current?.audioEl.current;
        if (player) {
            const progress = (player.currentTime / player.duration) * 100;
            setAudioProgress(progress);
            setCurrentTime(player.currentTime);
            setTotalDuration(player.duration);

            // Force the progress bar to update to 100% when the audio ends
            if (player.currentTime >= player.duration) {
                setAudioProgress(100);
                setCurrentTime(player.duration);
            }
        }
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <Container sx={{mt: 5, textAlign: 'center'}}>
                    <Typography variant="h6" gutterBottom>
                        Score: {gameScore}
                    </Typography>
                    <Typography variant="h4"
                                style={{visibility: currQuestionIndex >= audioClips.length ? 'hidden' : 'visible'}}
                                gutterBottom>
                        Line {Math.min(currQuestionIndex + 1, audioClips.length)}
                    </Typography>
                    <IconButton aria-label="volume" sx={{fontSize: 100, color: 'grey'}} disabled={!isLeader}>
                        <VolumeUpIcon fontSize="inherit"/>
                    </IconButton>
                    <PlayPauseButton onClick={isPlaying ? handlePause : handlePlay}
                                     disabled={!isLeader || isPlayDisabled}>
                        {isPlaying ? 'Pause' : 'Play'}
                    </PlayPauseButton>

                    <Box>
                        <ReactAudioPlayer
                            autoPlay={false}
                            src={currQuestionIndex >= audioClips.length ? audioClips[0].src : audioClips[currQuestionIndex].src}
                            ref={audioPlayerRef}
                            onPlay={() => handlePlay()}
                            onPause={() => handlePause()}
                            onListen={handleAudioTimeUpdate}
                            controls={false}
                            style={{visibility: currQuestionIndex >= audioClips.length ? 'hidden' : 'visible'}}
                            onEnded={() => setAudioProgress(100)} // Ensure progress is 100% when the audio ends
                            onLoadedMetadata={() => {
                                const player = audioPlayerRef.current?.audioEl.current;
                                if (player) {
                                    setTotalDuration(player.duration);
                                }
                            }}
                        />
                        <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                            <LinearProgress variant="determinate" value={audioProgress} sx={{flexGrow: 1}}/>
                            <Typography variant="body2" sx={{ml: 2}}>
                                {formatTime(currentTime)} / {formatTime(totalDuration)}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{mt: 3}}>
                        <GuessButton onClick={() => handleGuess('SCAM')} variant="contained" disabled={!isLeader}>
                            SCAM
                        </GuessButton>
                        <GuessButton onClick={() => handleGuess('REAL')} variant="contained" disabled={!isLeader}>
                            REAL
                        </GuessButton>
                    </Box>

                    <Box sx={{mt: 3}}>
                        <Typography variant="h6">
                            Current Clip: {Math.min(currQuestionIndex + 1, audioClips.length)} / {audioClips.length}
                        </Typography>
                    </Box>
                </Container>

                <Dialog
                    open={explanationOpen}
                    onClose={handleExplanationClose}>
                    <DialogContent>
                        <Typography variant="h6" sx={{color: guessResult === 'Correct' ? 'green' : 'red', mb: 2}}>
                            {guessResult}!
                        </Typography>
                        {/*subtract 1 because index has already progressed when the explanation is shown*/}
                        {audioClips[currQuestionIndex - 1] && (
                            <Typography>{audioClips[currQuestionIndex - 1].explanation}</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {/* Only the leader can click the Next button */}
                        <Button onClick={handleExplanationClose} color="primary" disabled={!isLeader}>
                            Next
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </ThemeProvider>
    );
};

export default PhantomLines;

