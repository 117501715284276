import React, {useEffect, useRef, useState} from "react";
import {useDrop} from "react-dnd";
import {styled, ThemeProvider} from "@mui/material/styles";
import {theme} from "../../../styling/palette";
import {Box, Paper, Typography} from "@mui/material";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockIcon from "@mui/icons-material/Lock";
import SecurityIcon from "@mui/icons-material/Security";
import InstagramIcon from "@mui/icons-material/Instagram";
import {BucketProps, ItemTypes} from "../../types";
import eventEmitter from "../../../contexts/EventEmitter";
const HoverSFX = require('../../../assets/PolicyHover.mp3');


const StyledBucket = styled(Paper)(({theme}) => ({
    minHeight: '60px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: '#007bff',
    color: theme.palette.generic.dark,
    fontSize: '0.8rem',
    textAlign: 'center',
    transition: 'background-color 0.2s ease, transform 0.2s ease',
    border: `solid ${theme.palette.generic.dark}`,
    '&.bucket-hover': {
        transform: 'scale(1.4)', // Enlarge slightly on hover
        boxShadow: '0 0 10px rgba(0, 123, 255, 0.5)',
    }
}));

const Bucket: React.FC<BucketProps> = ({policy, onDrop, label, ripple, submitPolicy, onLeaderAction}) => {

    const [hover, setHover] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(new Audio(HoverSFX));

    const [{isOver, canDrop}, ref] = useDrop({
        accept: ItemTypes.CARD,
        drop: () => {
            onDrop(policy);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    useEffect(() => {
        const handleStartHover = (eventPolicy: string) => {
            if (eventPolicy === policy) {
                setHover(true);
            } else {
                setHover(false);
            }
        };

        const handleEndHover = (eventPolicy: string) => {
            if (eventPolicy === policy || eventPolicy === "all") {
                setHover(false);
            }
        };

        // Add event listeners for hover start and end
        eventEmitter.on("startHover", handleStartHover);
        eventEmitter.on("endHover", handleEndHover);

        // Cleanup listeners when component unmounts
        return () => {
            eventEmitter.off("startHover", handleStartHover);
            eventEmitter.off("endHover", handleEndHover);
        };
    }, [policy]);


    useEffect(() => {
        if (isOver && canDrop) {
            onLeaderAction("startHover" + policy);
        }
    }, [isOver, canDrop, policy]);

    useEffect(() => {
        if (!isOver && hover) {
            onLeaderAction("endHover" + policy);
        }
    }, [isOver]);

    useEffect(() => {
        if (hover) {
            audioRef.current.currentTime = 0;
            audioRef.current.play().catch((error) => {
                console.error("Failed to play hover sound:", error);
            });
        }
    }, [hover]);

    function getBackgroundColor() {
        if (hover) {
            return theme.palette.generic.light; // Hover color
        }

        if (policy === submitPolicy) {
            if (ripple === 'Correct') {
                return '#97DBB3'; // Correct color
            } else if (ripple === 'Incorrect') {
                return '#F09A9A'; // Incorrect color
            }
        }

        return 'white'; // Default color
    }


    return (
        <ThemeProvider theme={theme}>
            <StyledBucket
                ref={ref}
                elevation={2}
                className={hover ? "bucket-hover" : ""}
                sx={{
                    backgroundColor: getBackgroundColor(),
                    alignContent: "center"
                }}
                data-cy={`bucket-${label}`}
            >
                <Box display={"flex"} gap={5}>
                    <CloudQueueIcon sx={{display: label === "A" ? "inline" : "none"}}/>
                    <MailOutlineIcon sx={{display: label === "B" ? "inline" : "none"}}/>
                    <CheckCircleOutlineIcon sx={{display: label === "C" ? "inline" : "none"}}/>
                    <LockIcon sx={{display: label === "D" ? "inline" : "none"}}/>
                    <SecurityIcon sx={{display: label === "E" ? "inline" : "none"}}/>
                    <InstagramIcon sx={{display: label === "F" ? "inline" : "none"}}/>
                    <Typography variant="body2" sx={{
                        color: theme.palette.generic.dark,
                        fontSize: 'min(0.9rem, 1.2vw)'
                    }}>{label + ". " + policy}</Typography>
                </Box>
            </StyledBucket>
        </ThemeProvider>
    );
};

export default Bucket;