import React from "react";
import {InstructionsType} from "../lobbies/types";

export const ItemTypes = {
    CARD: 'card',
};

export interface Card {
    id: number;
    text: string;
    policy: string;
    img: string
}

export interface CardComponentProps {
    id: number;
    text: string;
    img: string;
    isLeader: boolean;
    beingDragged: boolean;
    onLeaderAction: (leaderAction: string) => void;
}

export interface BucketProps {
    policy: string;
    label: string;
    ripple: string;
    submitPolicy: string;
    onDrop: (policy: string) => void;
    onLeaderAction: (leaderAction: string, data?: any) => void;
}

export interface GameProps {
    setInstructions: (instructions: InstructionsType) => void;
    setOnCompleteMessage: (message: string) => void;
    setTimeLimit: (time: number) => void;
    isLeader: boolean;
    isInstructionPopupOpen: boolean;
    leaderName: string;
    currQuestionIndex: number;
    gameScore: number;
    updateVoteMessage: (voteMessage: string) => void;
    onSubmit: (isCorrect: boolean, scoreIncrement: number) => void;
    onQuestionIndexUpdate: (newQuestionIndex: number) => void;
    onLeaderAction: (leaderAction: string, data?: any) => void;
    onComplete: () => void;
}