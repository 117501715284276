import React, {useState, useEffect, useRef} from 'react';
import {Container, Typography, Box, AppBar, Toolbar, Snackbar, Alert} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import {theme} from '../../styling/palette';
import image from './assets/FinaleImage.png';
import {useSocket} from '../../contexts/SocketContext';
import Logos from '../../components/Logos';
import StaticLeaderboard from '../../components/StaticLeaderboard';
import {buzzerSound, dingSound, levels, tickSound} from "./data";
import {LevelBox} from "./components/levelBox";
import {QuestionContainer} from "./components/questionBox";
import {AnswerButton} from "./components/answerButton";

import QuestionManager from './QuestionManager';
import MusicPlayer from "../../components/MusicPlayer";

const finaleMusic =  require('../../assets/Finale.mp3');
const musicSrcList: string[] =[finaleMusic];


// all the states to keep the game running. We use 20 seconds per question
const FinaleGame = () => {
    const {socket} = useSocket();
    const navigate = useNavigate();
    const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
    const [score, setScore] = useState(0);
    const [answerRevealed, setAnswerRevealed] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState<{ [key: number]: boolean }>({});
    const [timeLeft, setTimeLeft] = useState(20);
    const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [buzzedTeam, setBuzzedTeam] = useState<string | null>(null);
    const [updatePointsFinal, setUpdatePointsFinal] = useState(false);
    const tickAudio = useRef(new Audio(tickSound));
    const dingAudio = useRef(new Audio(dingSound));
    const buzzerAudio = useRef(new Audio(buzzerSound));


    // selecting an answer
    const handleAnswerClick = (answer: string) => {
        if (answerRevealed) return;
        setSelectedAnswer(answer);
    };

    // emits team finished if it was the last question
    useEffect(() => {
        if (updatePointsFinal) {
            setTimeout(() => {
                if (socket) {
                    socket.emit('teamFinished');
                }
            }, 2000);
        }
    }, [updatePointsFinal, socket]);


    // here is the main code. We use a UseEffect here, this essentially loops every question. If they answer it will check to see if its right or wrong and
    // update the leaderboard accordingly
    useEffect(() => {
        const handleLogic = async () => {
            if (timeLeft > 0) {
                const timerId = setTimeout(() => {
                    setTimeLeft(timeLeft - 1);
                    if (timeLeft - 1 <= 5) {
                        tickAudio.current.currentTime = 0;
                        tickAudio.current.play();
                    }
                }, 1000);
                return () => clearTimeout(timerId);
            } else {
                setAnswerRevealed(true);
                const currentQuestion = QuestionManager.getCurrentQuestion();
                const currentQuestionIndex = QuestionManager.getCurrentQuestionIndex();
                const isCorrect = selectedAnswer === currentQuestion?.answer;
                setCorrectAnswers((prev) => ({...prev, [currentQuestionIndex]: isCorrect}));

                if (isCorrect) {
                    dingAudio.current.volume = 0.5;
                    dingAudio.current.currentTime = 0;
                    await dingAudio.current.play();
                    const points = levels[currentQuestionIndex].points;
                    const nextScore = score + points;
                    setScore(nextScore);
                    await updateScore(points);

                } else {
                    buzzerAudio.current.currentTime = 0;
                    buzzerAudio.current.volume = 0.15;
                    await buzzerAudio.current.play();
                }

                if (!QuestionManager.isLastQuestion()) {
                    setTimeout(() => {
                        setAnswerRevealed(false);
                        setSelectedAnswer(null);
                        QuestionManager.goToNextQuestion();
                        setTimeLeft(20); // Reset timer
                    }, 2000);
                } else {
                    setUpdatePointsFinal(true);
                }

            }
        };
        void handleLogic();
    }, [timeLeft, selectedAnswer]);


    // socket events that it listens out for,
    useEffect(() => {
        if (socket) {
            socket.on('broadcastMessage', (data) => {
                setSnackbarMessage(data.message);
                setSnackbarOpen(true);
            });


            socket.on('buzz', (data) => {
                if (!buzzedTeam) {
                    setBuzzedTeam(data.teamName);
                }
            });

            socket.on('unbuzz', () => {
                setBuzzedTeam(null);
            });

            socket.on('navigateToEndGame', () => {
                console.log("received socket emission to go to leaderboard screen");
                navigate('/end-screen');
            });


            socket.on('scoreUpdated', (leaderboard) => {
                setRefreshLeaderboard(!refreshLeaderboard);  // Force leaderboard refresh on score update
            });

            return () => {
                socket.off('broadcastMessage');
                socket.off('buzz');
                socket.off('unbuzz');
                socket.off('navigateToEndGame');
                socket.off('scoreUpdated');
            };
        }
    }, [socket, buzzedTeam, navigate]);


    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };


    const updateScore = async (updatedScore: number) => {
        try {
            if (!socket) return;
            socket.emit('updateScore', updatedScore);
            setRefreshLeaderboard(!refreshLeaderboard);
        } catch (error) {
            console.error('Error updating score:', error);
        }
    };


const currentQuestion = QuestionManager.getCurrentQuestion();

    // main finale game UI
    return (
        <ThemeProvider theme={theme}>

            {musicSrcList && <MusicPlayer musicSrcList={musicSrcList} startPlay={true} />}
            <Box sx={{flexGrow: 1, position: 'relative', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
                <AppBar position="sticky" color="generic">
                    <Toolbar style={{justifyContent: 'space-between'}}>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1, textAlign: 'left'}}>
                            Who Wants To Be A Security Expert?
                        </Typography>
                        <Typography variant="h6" component="div">
                            {timeLeft}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Container sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', mt: 2, mb: 4}}>
                    <Box sx={{width: '20%', textAlign: 'left', border: '1px solid black', padding: 2, height: '100%'}}>
                        <StaticLeaderboard refresh={refreshLeaderboard}/>
                    </Box>
                    <Box sx={{width: '60%', textAlign: 'center', position: 'relative'}}>
                        <Box
                            component="img"
                            sx={{
                                width: '100%', // Adjust the width to make the image smaller
                                height: '100%',
                                mt: -2 // Add margin-top to move the image up
                            }}
                            alt="Finale Game"
                            src={image}
                        />
                        <Box sx={{
                            position: 'absolute',
                            bottom: '10%',
                            left: '50%',
                            transform: 'translate(-50%, 0)',
                            minWidth: '100%',
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            <QuestionContainer question={currentQuestion.question}/>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                mt: 2,
                                maxWidth: '74%',
                                width: '100%',
                                margin: '0 auto'
                            }}>
                                <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    {currentQuestion?.options.slice(0, 2).map((answer, index) => (
                                        <AnswerButton
                                            key={index}
                                            onClick={() => handleAnswerClick(answer)}
                                            className={
                                            answerRevealed &&
                                            selectedAnswer === currentQuestion.answer &&
                                            answer === selectedAnswer ? 'correct' : answerRevealed && selectedAnswer !== currentQuestion.answer && answer === selectedAnswer ? 'incorrect' : answerRevealed && answer === currentQuestion.answer ? 'correct' : selectedAnswer === answer ? 'selected' : 'unselected'}
                                            disabled={answerRevealed}
                                        >
                                            <Typography variant="caption">{answer}</Typography>
                                        </AnswerButton>
                                    ))}
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    {currentQuestion?.options.slice(2).map((answer, index) => (
                                        <AnswerButton
                                            key={index}
                                            onClick={() => handleAnswerClick(answer)}
                                            className={answerRevealed && selectedAnswer === currentQuestion.answer && answer === selectedAnswer ? 'correct' : answerRevealed && selectedAnswer !== currentQuestion.answer && answer === selectedAnswer ? 'incorrect' : answerRevealed && answer === currentQuestion.answer ? 'correct' : selectedAnswer === answer ? 'selected' : 'unselected'}
                                            disabled={answerRevealed}
                                        >
                                            <Typography variant="caption">{answer}</Typography>
                                        </AnswerButton>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width: '20%', textAlign: 'left', border: '1px solid black', padding: 2, height: '100%'}}>
                        {levels.slice().reverse().map((level, index) => {
                            const questionIndex = levels.length - index - 1;
                            const isCorrect = correctAnswers[questionIndex];
                            const isIncorrect = correctAnswers[questionIndex] === false;

                            return (
                                <LevelBox
                                    key={index}
                                    theme={theme}
                                    current={questionIndex === QuestionManager.getCurrentQuestionIndex()}
                                    correct={isCorrect}
                                    incorrect={isIncorrect}
                                >
                                    <Typography variant="body1">{level.level}</Typography>
                                    <Typography variant="body1">{level.points} points</Typography>
                                </LevelBox>
                            );
                        })}
                    </Box>
                </Container>
                <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto'}}>
                    <Logos/>
                </Box>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert onClose={handleCloseSnackbar} severity="info" sx={{width: '100%'}}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </ThemeProvider>
    );
};

export default FinaleGame;
