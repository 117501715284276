import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box} from "@mui/material";
import {useSocket} from "../contexts/SocketContext";
import {useTeam} from "../contexts/TeamContext";

interface UnanimousYesPopupProps {
    open: boolean;
    voteMessage: string;
    voteResult: 'neutral' | 'confirmed' | 'rejected';
    sid: string;
}

const UnanimousYesPopup: React.FC<UnanimousYesPopupProps> =
    ({
         open,
         voteMessage,
         voteResult,
         sid,
     }) => {
        const {socket} = useSocket();
        const [voted, setVoted] = useState(false);
        const [waitingMessage, setWaitingMessage] = useState("Waiting for team members to vote...");
        const [showOverrideButton, setShowOverrideButton] = useState(false);

        const {isLeader, userName, teamName} = useTeam();

        useEffect(() => {
            if (voteMessage.length === 0) {
                handleResponse(true);
                console.log("this player just joined, so skipping their vote.");
            }

        }, [voteMessage]);


        useEffect(() => {
            if (socket && open) {

                socket.on('updateVoteStatus', (data) => {
                    console.log("updateVoteStatus received, data is: ", data);
                    const {notYetVoted} = data;
                    console.log("notYetVoted is: ", notYetVoted);

                    const remainingVoters = notYetVoted.filter((name: string) => name !== userName);

                    if (remainingVoters.length === 0) {
                        setWaitingMessage('Processing vote...');
                    } else if (remainingVoters.length > 0) {
                        const waitingMessage = `${remainingVoters.join(', ')}`;
                        console.log("waitingMessage is: ", waitingMessage);
                        setWaitingMessage(`Waiting for ${waitingMessage} to vote...`);
                    }
                });

                // Cleanup listeners when component unmounts or popup closes
                return () => {
                    socket.off('updateVoteStatus');
                };
            }
        }, [socket]);


        useEffect(() => {
            if (open && isLeader) {
                const timer = setTimeout(() => {
                    setShowOverrideButton(true);
                }, 15000); // 15 seconds in milliseconds

                // Cleanup the timer when the component unmounts or dependencies change
                return () => clearTimeout(timer);
            }
        }, [open, isLeader]);


        const handleResponse = (response: boolean) => {
            console.log("Team member response received:", response);
            if (socket) {
                console.log(`Emitting response to server: { response: ${response}, teamName: ${teamName}, sid: ${sid} }`);
                socket.emit('teamMemberResponse', {response, teamName, sid});
                setVoted(true);
            }
        };

        const handleOverride = () => {
            if (socket) {
                socket.emit('overrideVote', {teamName});
                console.log(`Override vote emitted: { teamName: ${teamName}.`);
            }
        };

        return (
            <Dialog open={open} disableEscapeKeyDown sx={{ borderRadius: '15px'}}>
                <Box
                    sx={{
                        border: voteResult === "confirmed" ? "5px solid #97DBB3" : voteResult === "rejected" ? "5px solid #DA1C5C" : "none", // Conditionally apply green or red outline
                        transition: "border 0.1s ease", // Smooth transition for visual feedback
                    }}>
                <DialogTitle>Team Decision Required</DialogTitle>
                <DialogContent>
                    {isLeader || voted ? (
                        <Typography>{waitingMessage}</Typography>
                    ) : (
                        <>
                            <Typography>{voteMessage}</Typography>
                            <Typography>Do you agree with this action?</Typography>
                        </>
                    )}
                </DialogContent>
                {!isLeader && !voted && (
                    <DialogActions>
                        <Button onClick={() => handleResponse(true)} color="primary"
                                data-cy="agree-button">Agree</Button>
                        <Button onClick={() => handleResponse(false)} color="secondary"
                                data-cy="disagree-button">Disagree</Button>
                    </DialogActions>
                )}
                {isLeader && showOverrideButton && (
                    <DialogActions>
                        <Button onClick={handleOverride} color="primary">
                            Override
                        </Button>
                    </DialogActions>
                )}
                </Box>
            </Dialog>
        );
    };

export default UnanimousYesPopup