import React, { createContext, useContext, useState, useEffect } from 'react';

interface TeamContextType {
  teamName: string;
  userName: string;
  isLeader: boolean;
  leaderName: string;
  currGame: number;
  gameStarted: boolean;


  setTeamName: (teamName: string) => void;
  setUserName: (userName: string) => void;
  setIsLeader: (leader: boolean) => void;
  setLeaderName: (leaderName: string) => void;
  setCurrGame: (currGame: number) => void;
  setGameStarted: (hasGameStarted: boolean) => void;
}
/* 
this keeps track of the team state ensuring that a user stays on the same team. Uses JWT authenication tokens. 
*/

const TeamContext = createContext<TeamContextType | undefined>(undefined);

export const useTeam = () => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error('useTeam must be used within a TeamProvider');
  }
  return context;
};


export const TeamProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [teamName, setTeamName] = useState('');
  const [userName, setUserName] = useState('');
  const [isLeader, setIsLeader] = useState(false);
  const [leaderName, setLeaderName] = useState('');
  const [gameStarted, setGameStarted] = useState(false);
  const [currGame, setCurrGame] = useState(0);


  return (
    <TeamContext.Provider value={{
      teamName, setTeamName,
      userName, setUserName,
      isLeader, setIsLeader,
      leaderName, setLeaderName,
      gameStarted, setGameStarted,
    currGame, setCurrGame}}>
      {children}
    </TeamContext.Provider>
  );
};
