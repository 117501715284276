import React from 'react';
import {theme} from "../styling/palette";

const Player = () => {

    return (
        <svg width="32" height="32" viewBox="0 0 28 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_2005_139" fill="white">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.3282 27.9968C20.901 26.6515 21.4947 25.2156 22.5773 24.3098C25.5928 21.7866 27.5108 17.995 27.5108 13.7554C27.5108 6.15851 21.3523 0 13.7554 0C6.15851 0 0 6.15851 0 13.7554C0 17.995 1.91797 21.7866 4.93357 24.3098C6.01609 25.2156 6.6098 26.6515 6.18261 27.9968L0.436585 46.0921C-0.177701 48.0266 1.26621 50 3.29589 50H24.2149C26.2446 50 27.6885 48.0266 27.0742 46.0921L21.3282 27.9968Z"/>
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.3282 27.9968C20.901 26.6515 21.4947 25.2156 22.5773 24.3098C25.5928 21.7866 27.5108 17.995 27.5108 13.7554C27.5108 6.15851 21.3523 0 13.7554 0C6.15851 0 0 6.15851 0 13.7554C0 17.995 1.91797 21.7866 4.93357 24.3098C6.01609 25.2156 6.6098 26.6515 6.18261 27.9968L0.436585 46.0921C-0.177701 48.0266 1.26621 50 3.29589 50H24.2149C26.2446 50 27.6885 48.0266 27.0742 46.0921L21.3282 27.9968Z" fill={theme.palette.generic.main}/>
            <path d="M6.18261 27.9968L2.37021 26.7862L6.18261 27.9968ZM27.0742 46.0921L23.2618 47.3027L27.0742 46.0921ZM22.5773 24.3098L20.0104 21.2421L22.5773 24.3098ZM21.3282 27.9968L17.5158 29.2074L21.3282 27.9968ZM23.5108 13.7554C23.5108 16.7609 22.1565 19.4463 20.0104 21.2421L25.1441 27.3776C29.0291 24.1268 31.5108 19.2291 31.5108 13.7554H23.5108ZM13.7554 4C19.1432 4 23.5108 8.36765 23.5108 13.7554H31.5108C31.5108 3.94937 23.5615 -4 13.7554 -4V4ZM4 13.7554C4 8.36765 8.36765 4 13.7554 4V-4C3.94937 -4 -4 3.94937 -4 13.7554H4ZM7.50046 21.2421C5.35428 19.4463 4 16.7609 4 13.7554H-4C-4 19.2291 -1.51833 24.1268 2.36667 27.3776L7.50046 21.2421ZM4.24899 47.3027L9.99502 29.2074L2.37021 26.7862L-3.37582 44.8815L4.24899 47.3027ZM24.2149 46H3.29589V54H24.2149V46ZM17.5158 29.2074L23.2618 47.3027L30.8866 44.8815L25.1406 26.7862L17.5158 29.2074ZM-3.37582 44.8815C-4.80916 49.3953 -1.44004 54 3.29589 54V46C3.97245 46 4.45375 46.6578 4.24899 47.3027L-3.37582 44.8815ZM2.36667 27.3776C2.40014 27.4056 2.38546 27.4122 2.36034 27.3424C2.32877 27.2547 2.28806 27.0448 2.37021 26.7862L9.99502 29.2074C11.0282 25.9536 9.50825 22.9221 7.50046 21.2421L2.36667 27.3776ZM24.2149 54C28.9508 54 32.32 49.3953 30.8866 44.8815L23.2618 47.3027C23.0571 46.6578 23.5384 46 24.2149 46V54ZM20.0104 21.2421C18.0026 22.9221 16.4826 25.9536 17.5158 29.2074L25.1406 26.7862C25.2228 27.0448 25.182 27.2547 25.1505 27.3424C25.1254 27.4122 25.1107 27.4056 25.1441 27.3776L20.0104 21.2421Z" fill={theme.palette.generic.main} mask="url(#path-1-inside-1_2005_139)"/>
        </svg>
    );
};

export default Player;
