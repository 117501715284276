import React, { useState, useEffect } from 'react';
import { useSocket } from '../contexts/SocketContext';
import { Box, Button, Collapse, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import { theme } from '../styling/palette';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import GroupIcon from '@mui/icons-material/Group';
import { useTeam } from '../contexts/TeamContext';

// This component contains the dropdown list of players in user's team.

interface TeamListProps {
    isLeader: boolean
}

// styling stuff

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: '#f5f5f5',
    border: '1px solid #ddd',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    maxHeight: 400,
    overflowY: 'auto',
    borderRadius: '8px',
    width: 'auto',
  }));

const StyledTable = styled(Table)(({ theme }) => ({
    '& thead th': {
        backgroundColor: theme.palette.turquoise.main,
        color: theme.palette.common.white,
    },
    '& tbody tr:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));
  

const TeamList: React.FC<TeamListProps> = () => {
    const [list, setList] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const { socket } = useSocket();
    const { teamName } = useTeam();

    // update team list with new list
    const thisBtl = (data: any) => {
      setList(data.list);
    }

    useEffect(() => {
        if(socket) {
            socket.on("broadcastTeamList", thisBtl);
            // immediately ask for and refresh team list when rendered
            socket.emit("broadcastTeamList");

            return () => {
                socket.off("broadcastTeamList", thisBtl);
            }
        }
    }, [socket])

    // close dropdown when clicked outside

    const handleClickOut = (ev: any) => {
      if(ev.target.id !== "teamListButton") {
        setOpen(false);
      }
    }

    return(
        <ThemeProvider theme = {theme}>
    <Box sx={{ position: 'relative' }}>
      <Button variant="contained" id="teamListButton" color="generic" onClick={() => setOpen(!open)} startIcon={<GroupIcon />}>
      {/* <GroupIcon /> */}
        {open ? `My Team: ${teamName}` : `My Team: ${teamName}`}
      </Button>
      <ClickAwayListener onClickAway={handleClickOut}>
      <Collapse in={open} sx={{position: 'absolute', top: '100%', transform: 'translateX(-45%)', zIndex: 1, width: 'auto' }}>
        <StyledPaper sx={{ mt: 1 }}>
          <Typography variant="h6" gutterBottom>
            My Team
          </Typography>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>Player Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((name, index) => (
                <TableRow key={index}>
                    <TableCell component="th" scope="row" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        {name}
                    </TableCell>
                </TableRow>
              ))}  
            </TableBody>
          </StyledTable>
        </StyledPaper>
      </Collapse>
      </ClickAwayListener>
    </Box>
    </ThemeProvider>
    )
}

export default TeamList;