import {createTheme} from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
        generic: Palette['primary'];
        turquoise: Palette['primary'];
        tur2: Palette['primary'];
    }

    interface PaletteOptions {
        generic: PaletteOptions['primary'];
        turquoise?: PaletteOptions['primary'];
        tur2?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        generic: true,
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        generic: true,
    }
}

declare module '@mui/material/AppBar' {
    interface AppBarPropsColorOverrides {
        generic: true,
    }
}

declare module '@mui/material/TextField' {
    interface TextFieldPropsColorOverrides {
        generic: true,
    }
}

declare module '@mui/material/OutlinedInput' {
    interface OutlinedInputPropsColorOverrides {
        generic: true,
    }
}

declare module '@mui/material/Select' {
    interface SelectPropsColorOverrides {
        generic: true,
    }
}

declare module '@mui/material/LinearProgress' {
    interface LinearProgressPropsColorOverrides {
        generic: true,
    }
}


export const theme = createTheme({
    palette: {
        generic: {
            main: '#8673ff',
            light:'#8f8ac2',
            dark: '#272075',
            contrastText:'#151142',
        },
        turquoise: {
            main: '#6FDBE0',
            light: '#A4DDE0',
            dark: '#40BABF',
            contrastText: "#194a4d",
        },

        tur2: {
            main: '#2D8286',
            light: '#40BABF',
            dark: '#194a4d',
            contrastText: "#FFFFFF"
        }
    }
})
