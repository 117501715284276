import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SocketProvider } from '../contexts/SocketContext';
import {TeamProvider} from "../contexts/TeamContext"
import GlobalEventListener from "../contexts/GlobalEventListener";

import OnboardingPage from "../lobbies/OnboardingPage";
import EnterUsernameRoom from "../lobbies/EnterUsernameRoom";
import FacilitatorDashboard from "../lobbies/FacilitatorDashboard";
import Lobby from "../lobbies/Lobby";
import BaseRoom from "../rooms/BaseRoom";
import GameEnd from "../lobbies/GameEnd";
import FinaleGameIntro from "../rooms/FinaleGame/FinaleGameIntro";
import FinaleGame from "../rooms/FinaleGame/FinaleGame";
import EndScreenPodium from "../lobbies/EndScreen";



// all of the app pages go here.

function App() {

  return (
    <TeamProvider>
      <SocketProvider>
      <BrowserRouter>
      <GlobalEventListener />
        <Routes>
          <Route path="/" element={<OnboardingPage />} />
          <Route path="/email-entry" element={<EnterUsernameRoom />} />
          <Route path="/lobby" element={<Lobby />} />
          <Route path="/facilitator-dashboard" element={<FacilitatorDashboard />} />
          <Route path="/game" element={<BaseRoom />} />
          <Route path="/game-end" element={<GameEnd />} />
          <Route path="/finale-game" element={<FinaleGameIntro />} />
          <Route path="/finale-game-start" element={<FinaleGame />} />
          <Route path="/end-screen" element={<EndScreenPodium />} />

        </Routes>
      </BrowserRouter>
      </SocketProvider>
      </TeamProvider>

  );
}

export default App;