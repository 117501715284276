import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

export const AnswerButton = styled(Button)(({theme}) => ({
    minWidth: '150px',
    maxWidth: '250px',  // Set a maximum width
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    whiteSpace: 'normal',  // Allow text to wrap
    wordWrap: 'break-word',  // Break long words
    lineHeight: 1.2,
    fontSize: '0.875rem',  // Adjust the font size
    textAlign: 'center',
    '& > *': {
        transform: 'rotate(0deg)',
    },
    '&.selected': {
        backgroundColor: theme.palette.turquoise.main,
        color: '#000',
    },
    '&.correct': {
        backgroundColor: "#97DBB3",
        color: '#000',
    },
    '&.incorrect': {
        backgroundColor: "#F09A9A",
        color: '#000',
    },
    '&.unselected': {
        backgroundColor: '#fff',
        color: '#000',
    },
    border: '1px solid black',
    borderRadius: '50px',
    width: '45%',  // Make sure buttons take full width of their container
    height: 'auto',
    '@media (max-width: 600px)': {
        minWidth: '120px',
        padding: theme.spacing(1.5),
        fontSize: '0.75rem',
    },
}));
