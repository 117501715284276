import React, { useEffect, useState } from 'react';
import { Container, Typography, AppBar, Toolbar, Button, Box, Grid, Collapse, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { theme } from '../styling/palette';
import { ThemeProvider } from '@mui/material/styles';
import Leaderboard from '../components/Leaderboard';
import Logos from '../components/Logos';
import { useTeam } from '../contexts/TeamContext';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { useSocket } from '../contexts/SocketContext';
import TeamList from '../components/TeamList';

import door0 from '../assets/door0.png';
import door1 from '../assets/door1.png';
import door2 from '../assets/door2.png';
import door3 from '../assets/door3.png';
import door4 from '../assets/door4.png';
import imprisonedBob from '../assets/Bob.png';

const GameEnd: React.FC = () => {
  const navigate = useNavigate();
  const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
  const [leaderboardOpen, setLeaderboardOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const {teamName, currGame} = useTeam();
  const { socket } = useSocket();
  const { width, height } = useWindowSize(); // For responsive confetti
  const [confettiActive, setConfettiActive] = useState(true);


  const doors = [door0, door1, door2, door3, door4, imprisonedBob];
  const selectedDoor = doors[currGame-1] || door4;

  useEffect(() => {
    if (socket) {
      socket.on('broadcastMessage', (data: { message: React.SetStateAction<string>; }) => {
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
      });

      return () => {
        socket.off('broadcastMessage');
      };
    }
  }, [socket]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiActive(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const handleNextPage = () => {
    navigate('/lobby'); // Update the path to the next page as needed
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {confettiActive && <Confetti width={width} height={height} />}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar style={{top: 0}} position="sticky" color="generic">
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }} gap={2}>
              <TeamList isLeader={false} />
              <Leaderboard refresh={refreshLeaderboard} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" component="div" sx={{ mr: 2 }} visibility={"hidden"}>
                  {teamName} Escape Room Lobby
                </Typography>
              </Box>
          </Toolbar>
        </AppBar>

        <Container maxWidth="lg" sx={{ flex: '1 0 auto' }}>
          <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginTop: '50px' }}>
            <Grid item xs={12} md={4}>
              <img src={selectedDoor} alt="escape" style={{ maxWidth: '100%', height: 'auto' }} />
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
              <Typography variant="h4" component="div" style={{ fontWeight: 'bold' }}>
                Congratulations! You have escaped the room.
              </Typography>
              <Button
                variant="contained"
                color="generic"
                style={{ marginTop: '20px' }}
                onClick={handleNextPage}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Collapse in={leaderboardOpen}>
          <Leaderboard refresh={refreshLeaderboard} />
        </Collapse>
        <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto' }}>
          <Logos />
        </Box>
        
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default GameEnd;
